/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { NavLink } from "react-router-dom";

function DefaultNavbarLink({
  name,
  openHandler,
  closeHandler,
  children,
  collapseStatus,
  light,
  route,
  labelTextTransform,
  disableRouting,
  ...rest
}) {
  return (
    <>
      <SoftBox
        {...rest}
        mx={1}
        p={1}
        onMouseEnter={openHandler || undefined}
        onMouseLeave={closeHandler || undefined}
        display="flex"
        alignItems="baseline"
        color={light ? "white" : "dark"}
        sx={{ cursor: "pointer", userSelect: "none", ...rest.sx }}
      >
        <NavLink onClick={disableRouting ? (event) => event.preventDefault() : undefined} to={route} className={({ isActive }) => {
          let baseClassName = "main-menu-nav-link";
          return baseClassName + (isActive ? " active" : "");
        }
        }>
          <SoftTypography
            variant="button"
            fontWeight="regular"
            textTransform={labelTextTransform}
            color="inherit"
            sx={{ fontWeight: "100%" }}
          >
            {name}
          </SoftTypography>
        </NavLink>
      </SoftBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of DefaultNavbarLink
DefaultNavbarLink.defaultProps = {
  openHandler: false,
  closeHandler: false,
  children: false,
  collapseStatus: false,
  light: false,
  route: "",
  labelTextTransform: "uppercase",
  disableRouting: false,
};

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.propTypes = {
  name: PropTypes.string.isRequired,
  openHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  closeHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  route: PropTypes.string,
  labelTextTransform: PropTypes.string,
  disableRouting: PropTypes.bool,
};

export default DefaultNavbarLink;
