import { useState } from "react";

import ImgsViewer from "react-images-viewer";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";

import image1 from "assets/images/ecommerce/wooden-table.jpeg";

function ProductImages({ imageUrls }) {
  const [currentImage, setCurrentImage] = useState(imageUrls && imageUrls.length > 0 ? imageUrls[0] : image1);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  return (
    <SoftBox>
      <ImgsViewer
        imgs={imageUrls.map(img => ({ src: img }))}
        isOpen={imgsViewer}
        onClose={closeImgsViewer}
        currImg={imgsViewerCurrent}
        onClickPrev={imgsViewerPrev}
        onClickNext={imgsViewerNext}
        backdropCloseable
      />

      <SoftBox
        component="img"
        src={currentImage}
        alt="Slika proizvoda"
        shadow="lg"
        borderRadius="lg"
        width="100%"
        onClick={openImgsViewer}
      />
      <SoftBox mt={2} pt={1}>
        <Grid container spacing={3}>
          {imageUrls.map((img, i) =>
            <Grid key={i} item xs={2}>
              <SoftBox
                component="img"
                id={i}
                src={img}
                alt="Mala slika proizvoda"
                borderRadius="lg"
                shadow="md"
                width="100%"
                sx={{ cursor: "pointer", height: "100%", objectFit: "cover" }}
                onClick={handleSetCurrentImage}
              />
            </Grid>)
          }
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

ProductImages.defaultProps = {
  imageUrls: [],
};

ProductImages.propTypes = {
  imageUrls: PropTypes.array
};

export default ProductImages;
