import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftAvatar from "components/SoftAvatar";
import SoftTypography from "components/SoftTypography";
import { RESOURCES_SERVER_URL_PREFIX } from "api";
import { useEffect } from "react";

function OrderInfo({ products, currency }) {
  useEffect(() => {
    console.log('products', products);
  }
    , [products]);
  return (
    <Grid container spacing={3} direction="column">
      {products && products.map((p, i) => (
        <Grid item key={i} xs={12} md={6}>
          <SoftBox display="flex" alignItems="center">
            <SoftBox mr={2}>
              <SoftAvatar variant="rounded" size="xxl" src={`${RESOURCES_SERVER_URL_PREFIX}${p.imageLocation}`} alt={p.name} />
            </SoftBox>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h6" fontWeight="medium">
                {p.name}
              </SoftTypography>
              <SoftBox mb={2}>
                <SoftTypography variant="button" fontWeight="regular" color="text">
                  Količina: {p.quantity}
                </SoftTypography>
              </SoftBox>
              <SoftBox display="flex" gap="0.5rem">
                {(currency === "BAM" ? p.quantity * p.unitPriceBam : p.quantity * p.unitPriceEur) - p.calculatedPrice > 0.001 &&
                  <SoftTypography sx={({ typography }) => ({
                    fontSize: typography.size.sm,
                    fontWeight: typography.fontWeightRegular,
                    color: '#000',
                    fontStyle: 'italic',
                    textDecoration: "line-through"
                  })}>
                    {currency === "BAM" ? p.quantity * p.unitPriceBam : p.quantity * p.unitPriceEur} {currency}
                  </SoftTypography>
                }
                <SoftTypography sx={({ typography }) => ({
                  fontSize: typography.size.sm,
                  fontWeight: typography.fontWeightBold,
                  color: '#000',
                })}>
                  &nbsp;&nbsp;{p.calculatedPrice} {currency}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
      ))}
    </Grid>
  );
}

OrderInfo.defaultProps = {
  products: [],
  currency: ""
};

OrderInfo.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
  currency: PropTypes.string
};

export default OrderInfo;
