// src/mockRevenueData.js
import moment from 'moment';

const generateMockDailyOrdersData = () => {
  const data = [];
  const today = moment();

  for (let i = 0; i < 30; i++) {
    const date = today.clone().subtract(i, 'days');
    const dayOfWeek = date.day();
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Sunday or Saturday
    const baseOrders = isWeekend ? 150 : 100; // Higher base orders on weekends
    const fluctuation = Math.floor(Math.random() * 30) - 15; // Random fluctuation between -15 and 15
    const currentValue = baseOrders + fluctuation;
    const previousValue = baseOrders + fluctuation - Math.floor(Math.random() * 10); // Slightly lower for previous period

    data.push({
      date: date.toISOString(),
      currentValue,
      previousValue,
    });
  }

  return data.reverse(); // Reverse to have the oldest date first
};

const generateMockDailySoldProductsData = () => {
  const data = [];
  const today = moment();

  for (let i = 0; i < 30; i++) {
    const date = today.clone().subtract(i, 'days');
    const dayOfWeek = date.day();
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Sunday or Saturday
    const baseProducts = isWeekend ? 300 : 200; // Higher base products sold on weekends
    const fluctuation = Math.floor(Math.random() * 50) - 25; // Random fluctuation between -25 and 25
    const currentValue = baseProducts + fluctuation;
    const previousValue = baseProducts + fluctuation - Math.floor(Math.random() * 20); // Slightly lower for previous period

    data.push({
      date: date.toISOString(),
      currentValue,
      previousValue,
    });
  }

  return data.reverse(); // Reverse to have the oldest date first
};

const mockDailyOrdersData = generateMockDailyOrdersData();
const mockDailySoldProductsData = generateMockDailySoldProductsData();

export { 
  mockDailyOrdersData,
  mockDailySoldProductsData,
};