import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

const ProductDescription = ({ title, textArray, textVariant, sx, titleSx }) => (
  <SoftBox sx={sx}>
    <SoftBox display="flex" alignItems="center" mb={2} >
      <SoftTypography variant="h3" sx={{ margin: "0 auto", ...titleSx }}>
        {title}
      </SoftTypography>
    </SoftBox>
    <SoftBox mb={2} display="flex" flexDirection="column" gap="1rem">
      {textArray.map((text, i) => (
        <SoftTypography variant={textVariant} key={i}
          sx={{
            color: "#000",
            position: "relative",
            paddingLeft: "1rem",
            '&::before': {
              content: '"•"',
              position: "absolute",
              left: 0,
              color: "#000",
            },
          }}
        >
          {text}
        </SoftTypography>
      ))}
    </SoftBox>
  </SoftBox>
)

ProductDescription.defaultProps = {
  title: "",
  textArray: [],
  textVariant: "body2",
  sx: {},
  titleSx: {},
};

ProductDescription.propTypes = {
  title: PropTypes.string,
  textArray: PropTypes.array,
  textVariant: PropTypes.string,
  sx: PropTypes.object,
  titleSx: PropTypes.object,
};

export default ProductDescription;