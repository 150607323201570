import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function BillingInformation({ fullName, address, city, country, postalCode, email, phoneNumber, notes }) {
  return (
    <>
      <SoftTypography variant="h6" fontWeight="medium">
        Podaci o kupcu
      </SoftTypography>
      <SoftBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        bgColor="grey-100"
        borderRadius="lg"
        p={3}
        mt={2}
      >
        <SoftBox width="100%" display="flex" flexDirection="column" lineHeight={1}>
          <SoftBox mb={1} lineHeight={0}>
            <SoftTypography variant="caption" fontSize="14px" color="text">
              Ime i prezime:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontSize="14px" fontWeight="medium" textTransform="capitalize">
                {fullName}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={1} lineHeight={0}>
            <SoftTypography variant="caption" fontSize="14px" color="text">
              Adresa:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontSize="14px" fontWeight="medium" textTransform="capitalize">
                {address}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={1} lineHeight={0}>
            <SoftTypography variant="caption" fontSize="14px" color="text">
              Poštanski broj:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontSize="14px" fontWeight="medium">
                {postalCode}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={1} lineHeight={0}>
            <SoftTypography variant="caption" fontSize="14px" color="text">
              Grad:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontSize="14px" fontWeight="medium">
                {city}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={1} lineHeight={0}>
            <SoftTypography variant="caption" fontSize="14px" color="text">
              Država:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontSize="14px" fontWeight="medium">
                {country}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={1} lineHeight={0}>
            <SoftTypography variant="caption" fontSize="14px" color="text">
              Email:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontSize="14px" fontWeight="medium">
                {email}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={1} lineHeight={0}>
            <SoftTypography variant="caption" fontSize="14px" color="text">
              Broj telefona:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontSize="14px" fontWeight="medium">
                {phoneNumber}
              </SoftTypography>
            </SoftTypography>
            <SoftBox mb={1} lineHeight={0}></SoftBox>
            <SoftTypography variant="caption" fontSize="14px" color="text">
              Napomena:&nbsp;&nbsp;&nbsp;
              <SoftTypography variant="caption" fontSize="14px" fontWeight="medium">
                {notes ? notes : "Nema napomene"}
              </SoftTypography>
            </SoftTypography>
            <SoftBox />
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </>
  );
}

BillingInformation.defaultProps = {
  fullName: -1,
  address: "",
  city: "",
  country: "",
  email: "",
  phoneNumber: "",
  postalCode: "",
  notes: ""
};

BillingInformation.propTypes = {
  fullName: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
  postalCode: PropTypes.string,
  notes: PropTypes.string
};

export default BillingInformation;
