import PropTypes from "prop-types";

import {
  useSoftUIController,
  selectOrder
} from "context";

import Checkbox from "@mui/material/Checkbox";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function IdCell({ id, checked }) {
  const [controller, dispatch] = useSoftUIController();
  const { selectedOrders } = controller;

  const onRowSelectionChange = (e) => selectOrder(dispatch, { id: id });

  return (
    <SoftBox display="flex" alignItems="center">
      <Checkbox defaultChecked={checked} checked={selectedOrders.includes(id)} onChange={onRowSelectionChange} />
      <SoftBox ml={1}>
        <SoftTypography variant="caption" fontWeight="medium" color="text">
          {id}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

IdCell.defaultProps = {
  checked: false,
};

IdCell.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default IdCell;
