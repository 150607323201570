import { Box, Modal } from "@mui/material";

import PropTypes from "prop-types";

import CreateNewOrderForm from "pages/admin/orders/components/create-new-order-form";

const CreateNewOrderModal = ({ open, handleOpen, handleClose, editMode, orderId }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: "50%",
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: {xs: 0, md: 4},
          width: { xs: "100%", md: "80%", xl: "60%" },
          height: { xs: "100%", md: "auto" }
        }}>
          <CreateNewOrderForm handleModalClose={handleClose} editMode={editMode} orderId={orderId} />
        </Box>
      </Modal>
    </div>
  );
}

CreateNewOrderModal.defaultProps = {
  open: false,
  handleOpen: () => { },
  handleClose: () => { },
  editMode: false,
  orderId: null
};

CreateNewOrderModal.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
  editMode: PropTypes.bool,
  orderId: PropTypes.number
};

export default CreateNewOrderModal;