import { Divider } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useDocumentTitle } from "hooks/use-document-title";
import PageLayout from "pages/layout/page-layout";

const CookiesPolicy = (props) => {
  useDocumentTitle("Mama za pet - Politika Kolačića");

  return (
    <PageLayout>
      <Divider sx={{ mt: "0" }} />
      <SoftBox display="flex" justifyContent="center" px="2rem">
        <SoftTypography variant="h4" fontWeight="bold" textTransform="uppercase">
          Politika Kolačića
        </SoftTypography>
      </SoftBox>
      <Divider sx={{ mb: "0.1rem" }} />
      <SoftBox mt="2rem" px="2rem">
        <SoftTypography variant="body2" paragraph>
          Naša web stranica koristi kolačiće da bi poboljšala vaše iskustvo prilikom korišćenja naših usluga. Ova Politika Kolačića objašnjava šta su kolačići, kako ih koristimo i koje opcije imate u vezi sa njima.
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="bold" mt="2rem">
          Šta su kolačići?
        </SoftTypography>
        <SoftTypography variant="body2" paragraph>
          Kolačići su male tekstualne datoteke koje su pohranjene na vašem uređaju (računaru, tabletu, mobilnom telefonu) kada posjetite određene web stranice. Kolačići omogućavaju web stranici da vas prepozna, pamti vaše postavke i poboljšava vaše korisničko iskustvo.
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="bold" mt="2rem">
          Kako koristimo kolačiće?
        </SoftTypography>
        <SoftTypography variant="body2" paragraph>
          Koristimo kolačiće kako bismo:
          <SoftBox component="ul" pl="1.5rem" sx={{ listStyleType: "disc" }}>
            <SoftBox component="li">
              Poboljšali funkcionalnost naše web stranice i omogućili vam korištenje određenih funkcija.
            </SoftBox>
            <SoftBox component="li">
              Pratili kako koristite našu web stranicu, kako bismo poboljšali sadržaj i usluge koje vam nudimo.
            </SoftBox>
            <SoftBox component="li">
              Analizirali podatke o prometu na našoj web stranici, što nam pomaže da optimizujemo njenu funkcionalnost.
            </SoftBox>
          </SoftBox>
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="bold" mt="2rem">
          Vaše opcije u vezi sa kolačićima
        </SoftTypography>
        <SoftTypography variant="body2" paragraph>
          Možete postaviti svoj web browser da odbija kolačiće ili vas upozori kada su kolačići poslati. Međutim, ako odlučite da odbijete kolačiće, možda nećete moći koristiti sve funkcije naše web stranice.
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="bold" mt="2rem">
          Promjene u Politici Kolačića
        </SoftTypography>
        <SoftTypography variant="body2" paragraph>
          Možemo povremeno ažurirati ovu Politiku Kolačića kako bismo odražavali promjene u tehnologiji, zakonima i našim poslovnim praksama. Svaka promjena će biti objavljena na ovoj stranici, i vaš nastavak korištenja naše web stranice će značiti da prihvatate ove izmjene.
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="bold" mt="2rem">
          Kontaktirajte nas
        </SoftTypography>
        <SoftTypography variant="body2" paragraph>
          Ako imate bilo kakva pitanja o ovoj Politici Kolačića, molimo vas da nas kontaktirate putem e-maila na support@mamazapet.com.
        </SoftTypography>
      </SoftBox>
    </PageLayout>
  );
};

export default CookiesPolicy;
