// src/mockRevenueData.js
import moment from 'moment';

const generateMockDailyData = () => {
  const data = [];
  const today = moment();

  for (let i = 0; i < 30; i++) {
    const date = today.clone().subtract(i, 'days');
    const dayOfWeek = date.day();
    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // Sunday or Saturday
    const baseValue = isWeekend ? 1200 : 800; // Higher base value on weekends
    const fluctuation = Math.floor(Math.random() * 200) - 100; // Random fluctuation between -100 and 100
    const currentValue = baseValue + fluctuation;
    const previousValue = baseValue + fluctuation - Math.floor(Math.random() * 100); // Slightly lower for previous period

    data.push({
      date: date.toISOString(),
      currentValue,
      previousValue,
    });
  }

  return data.reverse(); // Reverse to have the oldest date first
};

const generateMockWeeklyData = () => {
  const data = [];
  const today = moment().startOf('week');

  for (let i = 0; i < 52; i++) {
    const date = today.clone().subtract(i, 'weeks');
    const month = date.month();
    const isHolidaySeason = month === 11 || month === 0; // December and January
    const baseValue = isHolidaySeason ? 10000 : 6000; // Higher base value during holiday season
    const fluctuation = Math.floor(Math.random() * 2000) - 1000; // Random fluctuation between -1000 and 1000
    const currentValue = baseValue + fluctuation;
    const previousValue = baseValue + fluctuation - Math.floor(Math.random() * 500); // Slightly lower for previous period

    data.push({
      date: date.toISOString(),
      currentValue,
      previousValue,
    });
  }

  return data.reverse(); // Reverse to have the oldest date first
};

const mockWeeklyRevenueData = generateMockWeeklyData();
const mockDailyRevenueData = generateMockDailyData();

export { 
  mockDailyRevenueData,
  mockWeeklyRevenueData,
};