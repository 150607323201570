import { CardMedia, Divider, Grid, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PageLayout from "pages/layout/page-layout";
import HorizontalScroller from "pages/user/components/horizontal-scroller";
import { HashLink as Link } from 'react-router-hash-link';
import ProductImage from "../about-products/product-image";
import ProductDescription from "../about-products/product-description";
import brand from "assets/images/logos/mini-logo-transparent-green.png";
import SimpleGallery from "pages/user/components/simple-gallery";
import CoverText from "pages/user/components/cover-text";

import breakpoints from "assets/theme/base/breakpoints";
import { useEffect, useMemo, useState } from "react";
import SocialFollowComponent from "pages/user/components/social-follow-component";
import { useDocumentTitle } from "hooks/use-document-title";
import { useSoftUIController } from "context";
import { SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";
import Product from "pages/user/products/product";
import { get } from "api";
import ProductSkeleton from "../products/product/skeleton";
import { addCartItem } from "context";
import { updateProduct } from "context";
import { setOpenConfigurator } from "context";
import CoverImageWithText from "pages/user/components/cover-image-with-text";
import ProductPriceDetails from "pages/user/components/product-price-details";
import Signature from "pages/user/components/signature";
import FAQSection from "pages/user/home/components/faq-section";

export function mapProductNameToFrontPageURI(productName) {
  const mappings = {
    "Krema protiv bora": "krema-protiv-bora",
    "Zeolitna maska za lice": "zeolitna-maska-za-lice",
    "Serum za lice": "serum-za-lice",
    "All-In-One Set": "all-in-one-set",
    "Krema-Serum Set": "krema-serum-set",
    "Maska-Serum Set": "maska-serum-set",
  };

  const formattedName = productName.trim();

  return "/aboutproducts/" + (mappings[formattedName] || "mama-za-pet-setovi");
}

const Home = (props) => {
  useDocumentTitle("Mama za pet - Početna");

  const [controller, dispatch] = useSoftUIController();
  const { selectedCurrency, cartItems } = controller;

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setIsLoadingProducts(true);
    get(`${SERVER_URL_PREFIX}/api/web/products`)
      .then(response => response.json())
      .then(data => {
        setProducts(data)
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  }, []);

  const handleAddToCart = (product) => {
    const currentProduct = cartItems.find(item => item.id === product.id);
    get(`${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${currentProduct ? currentProduct.quantity + 1 : product.quantity}&currency=${selectedCurrency.value.toUpperCase()}`)
      .then(response => response.json())
      .then(data => {
        if (!currentProduct) {
          addCartItem(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice });
        } else {
          updateProduct(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice, quantity: currentProduct.quantity + 1 });
        }
      })
  }

  const handleAdd = (product) => { setTimeout(handleConfiguratorOpen, 300); handleAddToCart(product); };

  const getProductsAddToCardData = (productName) => {
    const product = products.find(p => p.name === productName);

    if (product) {
      const { id, name, imageLocation, quantity, unitPriceBam, unitPriceEur } = product;
      const price = selectedCurrency?.value === "bam" ? unitPriceBam : unitPriceEur;

      return {
        id: String(id),
        name: name,
        img: `${RESOURCES_SERVER_URL_PREFIX}${imageLocation}`,
        quantity: 1,
        unitPrice: price,
        unitQuantity: quantity,
      };
    }

    return undefined;
  };

  const styleForCoverImage = useMemo(() => {
    console.log(innerWidth);

    if (innerWidth < breakpoints.values.md) {
      if (innerWidth <= 365) {
        return {
          width: "100%",
          height: "310px"
        }
      }
      if (innerWidth > 400) {
        return {
          width: "100%",
          height: "360px"
        }
      }
    }
    if (innerWidth < breakpoints.values.xl) {
      return {
        maxHeight: "450px",
        height: "450px"
      }
    }
    return {
      maxHeight: "450px",
      height: "450px"
    }
  }, [innerWidth]);

  useEffect(() => {
    window.addEventListener("resize", () => setInnerWidth(window.innerWidth));
    return () => window.removeEventListener("resize", () => setInnerWidth(window.innerWidth));
  }, []);


  return (
    <PageLayout>
      <Grid container>
        <Grid item sx={{
          ...styleForCoverImage,
          backgroundImage: `url(${RESOURCES_SERVER_URL_PREFIX}/static/images/cover-cream-natalija-3840.webp)`,
          backgroundSize: "cover",
          width: "100%",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          pl: { xs: "0.2rem", md: "1rem", xl: "4rem" },
        }}>
          {/* Pokazni template za tekst preko slike - NE BRISATI */}
          <CoverText
            sx={{
              // backgroundColor: "rgba(128, 128, 128, 0.1)",
              padding: { xs: "1rem", xxl: "2rem" },
              borderRadius: "1rem",
              maxWidth: { xs: "70%", sm: "60%", md: "55%", xxl: "700px" },
              textAlign: "left",
            }}
            title="Zaboravi na skupe anti-age i detoks tretmane lica"
            description={innerWidth < breakpoints.values.sm ?
              "Isprobajte naše proizvode i prigrlite prirodnu njegu vaše kože." :
              "Naša linija proizvoda, bazirana na moćnom zeolitu, nudi sve što vam treba za osvježen i njegovan izgled. Isprobajte naše proizvode i prigrlite prirodnu njegu."
            }
            // hideDescription={innerWidth < breakpoints.values .lg ? true : false}
            titleVariant={innerWidth < breakpoints.values.md ? "h6" : "h3"}
            descriptionVariant={innerWidth < breakpoints.values.md ? "caption" : "body2"}
            component={
              <SoftButton
                component={Link}
                to="/store"
                color="info"
                // variant="gradient"
                sx={{ maxWidth: "20rem", fontSize: "0.7rem" }}
              >
                {/* <Icon>shopping_bag</Icon>&nbsp;  */}
                Posjeti prodavnicu
              </SoftButton>
            }
            variant={innerWidth < breakpoints.values.md ? "h5" : "h3"}
            content={[
              {
                order: 0,
                textArray: [
                  "U svakom pakovanju čeka te eterično ulje smilja koje još",
                  "nazivalju i zlatnim uljem za lice"
                ]
              },
              {
                order: 1,
                textArray: [
                  "Zaboravi na skupe anti-age i detoks tretmane lica",
                  "Sve što ti treba spakovano je u Kremi protiv",
                  "bora Mama za pet sa zeolitom"
                ]
              }
            ]}
          />
        </Grid>
      </Grid>

      <Signature />

      {/* Traka zeolit, ulja, priroda, fale ikonice */}
      <SoftBox
        display="flex"
        alignItems="flex-start"
        position="relative"
        // minHeight="16rem"
        justifyContent="space-between"
        flexDirection={innerWidth < breakpoints.values.lg ? "column" : "row"}
        px={innerWidth < breakpoints.values.lg ?
          (innerWidth < breakpoints.values.md ? "1rem" : "4rem") :
          (innerWidth > breakpoints.values.xl ? "5rem" : "1rem")}
        py={innerWidth < breakpoints.values.lg ? "2rem" : "2.5rem"}
        gap={innerWidth < breakpoints.values.lg ? "5rem" : "3rem"}
        sx={{
          // backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          //   `${linearGradient(
          //     rgba("#fafafa", 0.5),
          //     rgba("#fafafa", 0.9),
          //   )}`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
          backgroundColor: "#f8f8f8",
        }}
      >
        <SoftBox sx={{ background: "transparent" }} width="100%">
          <SoftBox>
            <SoftBox display="flex" flexDirection="column" alignItems="center" gap="1rem" justifyContent="center" mb={2}>
              <SoftBox component="img" src={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/nature-leaf-icon.png`} alt="List ikonica" width="2.5rem" />
              <SoftTypography variant="h6" fontWeight="bold">
                100% Prirodni proizvodi
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="center">
              <SoftTypography variant="caption" textAlign="center" sx={{ fontSize: "14px" }}>
                U svakom pakovanju čekaju te zeolit i eterična ulja koja pomažu
                kod akni, ožiljaka, dermatitisa, ekcema i snažno regenerišu kožu.
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="center" mt="2rem">
              <SoftButton
                variant='outlined'
                component={Link}
                to="/aboutproducts"
                rel="noreferrer"
                color="success"
              >
                Pročitaj više &nbsp; <Icon>arrow_forward</Icon>
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox width="100%">
          <SoftBox display="flex" flexDirection="column" alignItems="center" gap="1rem" mb={2} justifyContent="center">
            <SoftBox component="img" src={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/styled/hot-stones.png`} alt="Kamen ikonica" width="2.5rem" />
            <SoftTypography variant="h6" fontWeight="bold">
              Zeolit
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" justifyContent="center">
            <SoftTypography variant="caption" textAlign="center" sx={{ fontSize: "14px" }}>
              Preventivno djeluje na brojna oboljenja jer iz organizma eliminiše
              živu, toksine, viruse, alergene i brojne druge štetne supstance.
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" justifyContent="center" mt="2rem">
            <SoftButton
              variant='outlined'
              component={Link}
              to="/aboutproducts#about-zeolit"
              rel="noreferrer"
              color="success"
            >
              Pročitaj više &nbsp; <Icon>arrow_forward</Icon>
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <SoftBox width="100%">
          <SoftBox display="flex" flexDirection="column" alignItems="center" gap="1rem" mb={2} justifyContent="center">
            <SoftBox component="img" src={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/styled/eye-dropper.png`} alt="Pipeta ikonica" width="2.5rem" />
            <SoftTypography variant="h6" fontWeight="bold">
              Eterična ulja
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" justifyContent="center">
            <SoftTypography variant="caption" textAlign="center" sx={{ fontSize: "14px" }}>
              U svakom pakovanju čeka te eterično ulje smilja koje još
              nazivalju i zlatnim uljem za lice.
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" justifyContent="center" mt="2rem">
            <SoftButton
              variant='outlined'
              component={Link}
              to="/aboutproducts#about-etericna-ulja"
              rel="noreferrer"
              color="success"
            >
              Pročitaj više &nbsp; <Icon>arrow_forward</Icon>
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>

      {/* Text divider */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" py="5rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
            <SoftBox component="img" src={brand} alt="Mama za pet brand logo" sx={{ height: "4rem", mb: "1rem" }} />
            <SoftTypography variant="h3" sx={{ color: "#000" }}>Zaboravi na skupe anti-age i detoks tretmane lica</SoftTypography>
            <SoftTypography variant="body2" sx={{ color: "#000", mt: "1rem" }}>Sve što ti treba spakovano je u Kremi protiv bora Mama za pet sa zeolitom</SoftTypography></SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>

      <CoverImageWithText
        imageUrl={`${RESOURCES_SERVER_URL_PREFIX}/static/images/shooting/landscape/resize00001.webp`}
        title="Najbolji prirodni proizvodi za njegu kože"
        description={`Naša linija proizvoda, bazirana na moćnom zeolitu, nudi sve što vam treba za osvježen i njegovan izgled. Isprobajte naše proizvode i prigrlite prirodnu njegu. Pogledajte ponudu proizvoda u našoj prodavnici.`}
        buttonText="Posjeti prodavnicu"
        onButtonClick={() => alert('Button clicked!')}
      />

      {/* Text divider */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" py="5rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
            <SoftBox component="img" src={brand} alt="Mama za pet brand logo" sx={{ height: "4rem", mb: "1rem" }} />
            <SoftTypography variant="h3" sx={{ color: "#000" }}>Zaboravi na skupe anti-age i detoks tretmane lica</SoftTypography>
            <SoftTypography variant="body2" sx={{ color: "#000", mt: "1rem" }}>Sve što ti treba spakovano je u Kremi protiv bora Mama za pet sa zeolitom</SoftTypography></SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>

      {/* Kupi kremu za lice */}
      <Divider sx={{ mb: "0" }} />
      <Grid container>
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/krema-protiv-bora-square.webp`} />
        <Grid
          item
          lg={6}
          xs={12}
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
          px={{ xs: "1rem", lg: "3rem" }}
          pt={{ xs: "1rem", lg: "0" }}
        >
          <ProductDescription
            title="Krema protiv bora"
            titleLink={mapProductNameToFrontPageURI("Krema protiv bora")}
            textVariant="body"
            textArray={[
              "Sve što treba suvoj, kombinovanoj i masnoj koži.",
              // "Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot(takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti.",
              // "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice."
            ]}
          />
          <ProductPriceDetails
            product={products.find(p => p.name === "Krema protiv bora")}
            selectedCurrency={selectedCurrency?.value}
          />
          <SoftBox display="flex" alignItems="center" mt="1rem" gap="1rem">
            <SoftButton
              disabled={!products.find(p => p.name === "Krema protiv bora") || isLoadingProducts}
              variant='contained'
              color="success"
              onClick={() => handleAdd(getProductsAddToCardData("Krema protiv bora"))}
            >
              <Icon sx={{ marginRight: '0.5rem' }}>add_shopping_cart</Icon>
              Dodaj u korpu
            </SoftButton>
            <SoftButton
              variant='text'
              color="info"
              href={mapProductNameToFrontPageURI("Krema protiv bora")}
            >
              Saznaj više
              <Icon sx={{ marginLeft: '0.5rem' }}>navigate_next</Icon>
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>

      {/* Kupi masku za lice */}
      <Divider sx={{
        mt: { xs: "4rem", lg: "0" },
        mb: "0"
      }} />
      <Grid container flexDirection={{ xs: "column-reverse", lg: "row" }}>
        <Grid item
          lg={6}
          md={12}
          px={{ xs: "1rem", lg: "3rem" }}
          pt={{ xs: "1rem", lg: "0" }}
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          <ProductDescription
            title="Zeolitna maska za lice"
            titleLink={mapProductNameToFrontPageURI("Zeolitna maska za lice")}
            textVariant="body"
            textArray={[
              "Ova maska ce ti pomoci da se zauvijek rijesis bora!"
            ]}
          // textArray={[
          //   "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice. Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot (takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti.",
          //   "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice. Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot (takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti."
          // ]}
          />
          <ProductPriceDetails
            product={products.find(p => p.name === "Zeolitna maska za lice")}
            selectedCurrency={selectedCurrency?.value}
          />

          <SoftBox display="flex" alignItems="center" mt="1rem" gap="1rem">
            <SoftButton
              disabled={!products.find(p => p.name === "Zeolitna maska za lice") || isLoadingProducts}
              variant='contained'
              color="success"
              onClick={() => handleAdd(getProductsAddToCardData("Zeolitna maska za lice"))}
            >
              <Icon sx={{ marginRight: '0.5rem' }}>add_shopping_cart</Icon>
              Dodaj u korpu
            </SoftButton>
            <SoftButton
              variant='text'
              color="info"
              href={mapProductNameToFrontPageURI("Zeolitna maska za lice")}
            >
              Saznaj više
              <Icon sx={{ marginLeft: '0.5rem' }}>navigate_next</Icon>
            </SoftButton>
          </SoftBox>
        </Grid>
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/zeolitna-maska-za-lice-square.webp`} />
      </Grid>

      {/* Kupi serum za lice */}
      <Divider sx={{
        mt: { xs: "4rem", lg: "0" },
        mb: "0"
      }} />
      <Grid container >
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/serum-za-lice-square.webp`} />
        <Grid
          item
          lg={6}
          md={12}
          px={{ xs: "1rem", lg: "3rem" }}
          pt={{ xs: "1rem", lg: "0" }}
          sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
        >
          <ProductDescription
            title="Serum za lice"
            titleLink={mapProductNameToFrontPageURI("Serum za lice")}
            textVariant="body"
            textArray={[
              "Sve što treba suvoj, kombinovanoj i masnoj koži.",
              // "Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot(takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti.",
              // "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice."
            ]}
          />
          <ProductPriceDetails
            product={products.find(p => p.name === "Serum za lice")}
            selectedCurrency={selectedCurrency?.value}
          />
          <SoftBox display="flex" alignItems="center" mt="1rem" gap="1rem">
            <SoftButton
              disabled={!products.find(p => p.name === "Serum za lice") || isLoadingProducts}
              variant='contained'
              color="success"
              onClick={() => handleAdd(getProductsAddToCardData("Serum za lice"))}
            >
              <Icon sx={{ marginRight: '0.5rem' }}>add_shopping_cart</Icon>
              Dodaj u korpu
            </SoftButton>
            <SoftButton
              variant='text'
              color="info"
              href={mapProductNameToFrontPageURI("Serum za lice")}
            >
              Saznaj više
              <Icon sx={{ marginLeft: '0.5rem' }}>navigate_next</Icon>
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>

      {/* Kupi setove */}
      <Divider sx={{ m: "0" }} />
      <SoftBox display="flex" justifyContent="space-between" px="2rem" py="5rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center">
            <SoftBox component="img" src={brand} alt="Mama za pet brand logo" sx={{ height: "4rem", mb: "1rem" }} />
            <SoftTypography variant="h3" sx={{ color: "#000" }}>Mama za pet setovi</SoftTypography>
            <SoftTypography variant="body2" sx={{ color: "#000", mt: "1rem" }}>Naši setovi proizvoda sadrže sve što ti je potrebno za savršenu njegu lica</SoftTypography></SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>
      <Divider sx={{ m: "0" }} />
      <Grid
        container
        spacing={1}
        sx={{
          rowGap: "5rem",
        }}
      >
        {
          isLoadingProducts ?
            Array.from(new Array(3)).map((_, index) => (
              <ProductSkeleton key={index} />
            )) :
            products
              .filter(p => p.type === "COMPOSITE")
              .map((product) => (
                <Product
                  key={product.id}
                  id={product.id.toString()}
                  title={product.name}
                  type={product.type}
                  compositeProductItems={product.compositeProductItems}
                  price={selectedCurrency.value === 'bam' ? product.unitPriceBam.toString() : product.unitPriceEur.toString()}
                  currency={selectedCurrency}
                  quantity={product.quantity}
                  image={`${RESOURCES_SERVER_URL_PREFIX}${product.imageLocation}`}
                  onAddToCart={handleAddToCart}
                  specialOffers={product.specialOffers}
                  lazyLoading={true}
                />
              ))
        }
      </Grid>

      {/* Text divider */}
      {/* <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="7rem" pb="2rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="2rem" textAlign="center">
            <SoftTypography variant="h3" sx={{ color: "#000" }}>Žene širom regiona koriste Mama za pet proizvode! </SoftTypography>
            <SoftTypography variant="body2" sx={{ color: "#000" }}>Pogledajte sta kažu... ❤️</SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox> */}

      <Divider sx={{ mt: "5rem", mb: "3rem" }} />
      <Grid container height="100%">
        <Grid item xs={12} md={6} minHeight="100%">
          <SoftBox display="flex" justifyContent="space-between" px="4rem" minHeight="100%">
            <SoftBox></SoftBox>
            <SoftBox>
              <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center" minHeight="100%">
                <SoftBox component="img" src={brand} alt="Mama za pet brand logo" sx={{ height: "4rem", mb: "1rem" }} />
                <SoftTypography variant="h3" sx={{ color: "#000" }}>Pogledajte šta korisnici širom regiona kažu za Mama za pet proizvode!</SoftTypography>
                <SoftTypography variant="body2" sx={{ color: "#000", mt: "1rem" }}>Otkrijte iskustva zadovoljnih korisnika iz celog regiona sa revolucionarnim Mama za pet proizvodima koji olakšavaju svakodnevni život!</SoftTypography>
                <SoftButton
                  component={Link}
                  to="/store"
                  color="info"
                  // variant="gradient"
                  sx={{ maxWidth: "20rem", fontSize: "0.7rem", mt: "2rem" }}
                >
                  {/* <Icon>shopping_bag</Icon>&nbsp;  */}
                  Posjeti prodavnicu
                </SoftButton>
              </SoftBox>
            </SoftBox>
            <SoftBox></SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <SoftBox sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
            <SoftBox component="img" src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/home/reviews-shots-large.webp`} alt="Reviews" sx={{ width: "100%", objectFit: "cover" }} />
          </SoftBox>
        </Grid>
      </Grid>

      <Divider sx={{ mt: "4rem", mb: "3rem" }} />

      {/* Text divider */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="2rem" pb="5rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SocialFollowComponent />
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>

      <Grid container >
        <Grid item md={0} sx={{ width: "100%", maxHeight: "40rem", minHeight: "20rem" }}>
          <Grid item sx={{
            ...styleForCoverImage,
            backgroundImage: `url(${RESOURCES_SERVER_URL_PREFIX}/static/images/cover-cream-natalija-3840.webp)`,
            backgroundSize: "cover",
            width: "100%",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            pl: { xs: "1rem", xxl: "6rem" },
          }}>

          </Grid>
          {/* <CardMedia
            component="video"
            src={`${RESOURCES_SERVER_URL_PREFIX}/static/videos/video-krema-1.mp4`}
            autoPlay
            loop
            muted
            loading="lazy"
            sx={{ borderRadius: "0", p: 0, m: 0, height: "100%", width: "100%", objectFit: "cover" }} /> */}
        </Grid>
        {/* <Grid item md={12} sx={{ display: { xs: "none" } }}>
          <CardMedia
            component="video"
            src={`${RESOURCES_SERVER_URL_PREFIX}/static/videos/video-lice-2.mp4`}
            autoPlay
            loop
            muted
            sx={{ borderRadius: "0", p: 0, m: 0, height: "100%", width: "100%", objectFit: "cover" }} />
        </Grid> */}
      </Grid>

      {/* Text divider */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="5rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="2rem" textAlign="center">
            <SoftTypography variant="h3" sx={{ color: "#000" }}>Uživajte u slikama</SoftTypography>
            <SoftBox display="flex" gap="3rem">
              <SoftTypography variant="body2" sx={{ color: "#000" }}>Svaki put kada zelite da uzivate, pogledajte slike ispod</SoftTypography>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>

      {/* Gallery */}
      <SoftBox sx={{
        mt: { xs: "3rem", md: "5rem" },

      }}>
        <SimpleGallery
          galleryId="my-test-gallery"
          images={[
            {
              largeURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-1.webp`,
              thumbnailURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-1.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-2.webp`,
              thumbnailURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-2.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-3.webp`,
              thumbnailURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-3.webp`,
              width: 5760,
              height: 3840,
            },
            {
              largeURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-2.webp`,
              thumbnailURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-2.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-1.webp`,
              thumbnailURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-1.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-3.webp`,
              thumbnailURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-3.webp`,
              width: 5760,
              height: 3840,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg',
              width: 1875,
              height: 2500,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-200.jpg',
              width: 1669,
              height: 2500,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
              width: 2500,
              height: 1666,
            },
            {
              largeURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-1.webp`,
              thumbnailURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-1.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-2.webp`,
              thumbnailURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-2.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg',
              width: 1875,
              height: 2500,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-200.jpg',
              width: 1669,
              height: 2500,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
              width: 2500,
              height: 1666,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg',
              width: 1875,
              height: 2500,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/2/img-200.jpg',
              width: 1669,
              height: 2500,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
              width: 2500,
              height: 1666,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/1/img-200.jpg',
              width: 1875,
              height: 2500,
            },
            {
              largeURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-1.webp`,
              thumbnailURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-1.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/slider-image-2.webp`,
              thumbnailURL:
                `${RESOURCES_SERVER_URL_PREFIX}/static/images/home-slider/thumbnails/slider-image-thumbnail-2.webp`,
              width: 1351,
              height: 1800,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
              width: 2500,
              height: 1666,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
              width: 2500,
              height: 1666,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
              width: 2500,
              height: 1666,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
              width: 2500,
              height: 1666,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
              width: 2500,
              height: 1666,
            },
            {
              largeURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-2500.jpg',
              thumbnailURL:
                'https://cdn.photoswipe.com/photoswipe-demo-images/photos/3/img-200.jpg',
              width: 2500,
              height: 1666,
            },
          ]}
        />
      </SoftBox>

      {/* Text divider */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="5rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SocialFollowComponent />
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>

      {/* Text divider */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" pt="5rem" pb="2rem">
        <SoftBox></SoftBox>
        <SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="2rem" textAlign="center">
            <SoftTypography variant="h3" sx={{ color: "#000" }}>Često postavljana pitanja</SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>
      <FAQSection />

    </PageLayout>
  )
}

export default Home;