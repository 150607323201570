import { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React example components
import DefaultNavbarLink from "app/components/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "app/components/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Soft UI Dashboard PRO React base styles
import brand from "assets/images/logos/logo-transparent-green.png";
import breakpoints from "assets/theme/base/breakpoints";

// DefaultNavbar dropdown menus
import PagesMenu from "app/components/Navbars/DefaultNavbar/Menus/PagesMenu";
import SoftSelect from "components/SoftSelect";
import CountryCell from "app/components/default-navbar/country-cell";
import { IconButton } from "@mui/material";
import SoftBadge from "components/SoftBadge";
import { useSoftUIController, setOpenConfigurator, selectCurrency } from "context";
import { RESOURCES_SERVER_URL_PREFIX } from "api";

function DefaultNavbar({ routes, transparent, light, action }) {
  const [pagesMenu, setPagesMenu] = useState(false);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [tabletView, setTabletView] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { cartItems, selectedCurrency } = controller;

  const openPagesMenu = ({ currentTarget }) => setPagesMenu(currentTarget);
  const closePagesMenu = () => setPagesMenu(false);
  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.xxl) {
        setTabletView(true);
      }
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  const handleChangeCurrency = (value) =>
    selectCurrency(dispatch, { value: value.value, label: value.label.props.name });

  return (
    <Container
      sx={{
        maxWidth: "100% !important",
        paddingRight: "1rem !important",
        paddingLeft: "1rem !important",
      }}
    >
      <SoftBox
        py={1}
        width="100%"
        minWidth="100%"
        borderRadius="section"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        left={0}
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <SoftBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </SoftBox>
        {/* Start: Left side of the navbar */}
        <SoftBox component={Link} to="/" py={transparent ? 1.5 : 0.75} lineHeight={1}>
          <SoftBox component="img" alt={"Mama za pet brand"} src={brand} sx={{ height: "4rem" }} />
        </SoftBox>
        {/* End: Left side of the navbar */}

        {/* Start: Middle side of the navbar */}
        <SoftBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
          <DefaultNavbarLink
            name="Početna"
            light={light}
            className="main-menu-nav-link"
            route="/"
          />
          <DefaultNavbarLink
            name="Prodavnica"
            light={light}
            className="main-menu-nav-link"
            route="/store"
          />
          <DefaultNavbarLink
            name="Proizvodi"
            openHandler={openPagesMenu}
            closeHandler={closePagesMenu}
            light={light}
            className="main-menu-nav-link"
            route="/aboutproducts"
            disableRouting
          />
          {/* <DefaultNavbarLink
            name="Sastojci"
            light={light}
            className="main-menu-nav-link"
            route="/ingredients"
          /> */}
          <DefaultNavbarLink
            name="Recenzije"
            light={light}
            className="main-menu-nav-link"
            route="/reviews"
          />
          <DefaultNavbarLink
            name="O meni"
            light={light}
            className="main-menu-nav-link"
            route="/about"
          />
        </SoftBox>
        {/* End: Middle side of the navbar */}

        {/* Start: Right side of the navbar */}
        <SoftBox display="flex" width={{ lg: "9rem" }} sx={{ justifyContent: "flex-end" }}>
          {/* <SoftBox display={{ xs: "none", lg: "flex" }}>
            <SoftSelect
              defaultValue={{ value: selectedCurrency.key, label: <CountryCell name={selectedCurrency.label} image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/currency/${selectedCurrency.key}.png`} /> }}
              options={[
                {
                  value: "bam", label: <CountryCell name="BAM" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/currency/bam.png`} />
                },
                {
                  value: "eur", label: <CountryCell name="EUR" image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/currency/eur.png`} />
                },
              ]}
              onChange={handleChangeCurrency}
              value={{ value: selectedCurrency.value, label: <CountryCell name={`${selectedCurrency.label}`} image={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/currency/${selectedCurrency.value}.png`} /> }}
              borderWidth={0}
              minWidth="9rem"
              withoutBorders
              className="react-select-container-currency"
            />
          </SoftBox> */}
          {cartItems.length === 0 ? (
            <IconButton
              size="medium"
              color="inherit"
              // sx={navbarIconButton}
              aria-controls="notification-menu"
              aria-haspopup="true"
              variant="contained"
              onClick={() => setOpenConfigurator(dispatch, true)}
            >
              <Icon className={light ? "text-white" : "text-dark"}>add_shopping_cart</Icon>
            </IconButton>
          ) : (
            <SoftBadge
              badgeContent={cartItems.reduce((a, b) => a + b.quantity, 0)}
              size="xs"
              overlap="circular"
            >
              <IconButton
                size="medium"
                color="inherit"
                // sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={() => setOpenConfigurator(dispatch, true)}
              >
                <Icon className={light ? "text-white" : "text-dark"}>add_shopping_cart</Icon>
              </IconButton>
            </SoftBadge>
          )}
        </SoftBox>
        {/* End: Right side of the navbar */}
      </SoftBox>
      <PagesMenu routes={routes} open={pagesMenu} close={closePagesMenu} />

      {mobileView && (
        <DefaultNavbarMobile
          routes={routes}
          open={mobileNavbar}
          close={closeMobileNavbar}
          onMenuItemClick={() => setTimeout(closeMobileNavbar, 10)}
        />
      )}
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;
