import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

const CoverText = ({ content, variant, title, titleVariant, description, descriptionVariant, component, hideDescription, ...rest }) => {
  return (
    <SoftBox display="flex" flexDirection="column" alignItems="flex-start" gap="1rem" {...rest}>
      <SoftTypography variant={titleVariant} sx={{ color: "#000", fontWeight: "600" }}>
        {title}
      </SoftTypography>
      <SoftTypography variant={descriptionVariant} sx={{ color: "#000", mb: "1rem", display: `${hideDescription ? "none" : "flex"}` }} >
        {description}
      </SoftTypography>
      {component}
    </SoftBox>
  )
}

CoverText.defaultProps = {
  content: [],
  variant: "h2",
  title: "",
  description: "",
  component: null,
  hideDescription: false,
  titleVariant: "h4",
  descriptionVariant: "body2"
};

CoverText.propTypes = {
  content: PropTypes.array,
  variant: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  component: PropTypes.node,
  hideDescription: PropTypes.bool,
  titleVariant: PropTypes.string,
  descriptionVariant: PropTypes.string
};

export default CoverText;