import { Grid, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import Link from "components/Link/Link";
import { mapProductNameToFrontPageURI } from "pages/user/home";
import ProductBadge from "pages/user/product-pages/components/product-badge";

const SuggestedProduct = ({
  id,
  title,
  type,
  compositeProductItems,
  price,
  currency,
  image,
  quantity,
  onAddToCart,
  specialOffers,
  onlyInfo,
  lazyLoading
}) => {
  return (
    <Grid item width="270px" >
      <SoftBox display="flex" flexDirection="column" position="relative">
        {!onlyInfo && specialOffers.length > 0 && <SoftBox display="flex" flexDirection="column" position="absolute"
          sx={{
            backgroundColor: "#A9ABAD",
            top: "0.5rem",
            left: "0.5rem",
            px: "0.7rem",
            py: "0.4rem",
            border: "1px solid #A9ABAD",
            borderRadius: "0.5rem"
          }}>
          <SoftBox display="flex" gap="0.3rem">
            <Icon sx={{ color: "#fff" }}>star</Icon>
            <SoftTypography sx={({ typography }) => ({
              fontSize: typography.size.sm,
              fontWeight: typography.fontWeightMedium,
              fontFamily: 'system-ui',
              color: "#fff"
            })}>
              AKCIJA
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        }
        {
          id === "4" && (
            <SoftBox display="flex" flexDirection="column" position="absolute"
              sx={{
                backgroundColor: "#7eddd3",
                top: "0.5rem",
                left: "0.5rem",
                px: "0.7rem",
                py: "0.4rem",
                border: "1px solid #7eddd3",
                borderRadius: "0.5rem"
              }}>
              <ProductBadge text="PREPORUČUJEMO" icon="new_releases" />
            </SoftBox>
          )
        }
        <RouterLink to={mapProductNameToFrontPageURI(title)}>
          <SoftBox
            component="img"
            loading={lazyLoading ? "lazy" : "eager"}
            src={image}
            alt={`Proizvod ${title}`}
            sx={{ cursor: 'pointer', width: '100%', height: 'auto', borderRadius: "0.5rem" }}
          />
        </RouterLink>
        <SoftBox display="flex" justifyContent="space-between" px="1rem" pt="1rem">
          {!onlyInfo &&
            <>
              <SoftBox display="flex" flexDirection="column">
                <SoftBox display="flex" flexDirection="column">
                  <Link
                    component={RouterLink}
                    to={mapProductNameToFrontPageURI(title)}
                    sx={{
                      width: 'fit-content'
                    }}
                  >
                    <SoftTypography sx={({ typography }) => ({
                      fontSize: "18px",
                      fontWeight: typography.fontWeightMedium,
                      color: '#000'
                    })}>
                      {title}&nbsp;&nbsp;
                    </SoftTypography>
                  </Link>

                  {type === "UNIT" ?
                    <>
                      <SoftTypography sx={({ typography }) => ({
                        fontSize: typography.size.sm,
                      })}>
                        Količina: &nbsp;<i>{quantity}</i>
                      </SoftTypography>
                      <SoftTypography sx={({ typography }) => ({
                        fontSize: typography.size.sm,
                      })}>
                        Akcija: &nbsp;<i>2 - 65KM | 3 - 95KM</i>
                      </SoftTypography>
                    </>
                    :

                    <SoftTypography sx={({ typography }) => ({
                      fontSize: typography.size.sm,
                    })}>
                      Sadrži: &nbsp;{compositeProductItems.map((item, i) => (
                        <i><span key={i}>{item.quantity !== 1 ? item.quantity + " x " : ''}{item.itemProduct.name}{i < compositeProductItems.length - 1 && ", "}</span></i>
                      ))}
                    </SoftTypography>}
                </SoftBox>
                <SoftTypography sx={({ typography }) => ({
                  fontSize: typography.size.lg,
                  fontWeight: typography.fontWeightMedium,
                  color: '#000',
                  mt: "0.8rem"
                })}>
                  {price} {currency.label}
                </SoftTypography>

                <SoftBox display="flex" alignItems="start" pt="1rem">
                  <SoftButton variant='contained' color="success" onClick={() => onAddToCart({
                    id: id,
                    name: title,
                    img: image,
                    quantity: 1,
                    unitPrice: price,
                    unitQuantity: quantity,
                  })}><Icon>add_shopping_cart</Icon>&nbsp;&nbsp;Dodaj u korpu</SoftButton>
                </SoftBox>
              </SoftBox>
            </>}
        </SoftBox>
      </SoftBox>
    </Grid >
  )
}

SuggestedProduct.defaultProps = {
  id: "",
  title: "",
  type: "UNIT",
  compositeProductItems: [],
  price: "",
  currency: {},
  image: "",
  onAddToCart: () => { },
  onlyInfo: false,
  quantity: "",
  specialOffers: [],
  lazyLoading: false
};

SuggestedProduct.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  compositeProductItems: PropTypes.array,
  price: PropTypes.string,
  currency: PropTypes.object,
  image: PropTypes.string,
  onAddToCart: PropTypes.func,
  onlyInfo: PropTypes.bool,
  quantity: PropTypes.string,
  specialOffers: PropTypes.array,
  lazyLoading: PropTypes.bool
};

export default SuggestedProduct;