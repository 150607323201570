import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ActionCell({ orderId, onDelete, handleEdit }) {
  return (
    <SoftBox display="flex" alignItems="center" gap="0.5rem">
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Detalji" placement="top">
          <Link to={`/order/${orderId}`}>
            <Icon color="secondary">visibility</Icon>
          </Link>
        </Tooltip>
      </SoftTypography>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={handleEdit}>
        <Tooltip title="Izmijeni stavku" placement="top">
          <Icon>edit</Icon>
        </Tooltip>
      </SoftTypography>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }} onClick={onDelete}>
        <Tooltip title="Ukloni stavku" placement="top">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography>
    </SoftBox>
  );
}

ActionCell.defaultProps = {
  orderId: -1,
  onDelete: () => { },
  handleEdit: () => { },
};

ActionCell.propTypes = {
  orderId: PropTypes.number,
  onDelete: PropTypes.func,
  handleEdit: PropTypes.func,
};

export default ActionCell;
