import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import moment from "moment";
import localization from "moment/locale/bs";

import { SoftUIControllerProvider } from "context";

import AdminApp from "app/AdminApp";
import UserApp from "app/UserApp";
import brand from "assets/images/logos/mini-logo-transparent-green.png";

import 'index.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
moment.locale("bs", localization);

const getSubdomain = () => {
  const x = window.location.hostname.split('.')[0];
  return x === 'localhost' ? '' : x;
}

const UnderConstructionPage = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px', fontFamily: "Roboto" }}>
      <img src={brand} alt="logo" style={{ width: '100px', height: '100px' }} />
      <h1>Mamazapet webshop je u fazi izrade</h1>
      <p>Očekujte nas uskoro!</p>
    </div>
  );
};

const hasAccessKey = localStorage.getItem("pristup") === "lozinka";

root.render(
  hasAccessKey ? (
    <BrowserRouter>
      <SoftUIControllerProvider>
        {getSubdomain() === 'platform' ? <AdminApp /> : <UserApp />}
      </SoftUIControllerProvider>
    </BrowserRouter>
  ) : (
    <UnderConstructionPage />
  )
);