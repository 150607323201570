import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftSelect from "components/SoftSelect";
import SoftTypography from "components/SoftTypography";

import { paymentStatusSelectData, shipmentStatusSelectData } from "pages/admin/order-details/data/selectData";
import SoftButton from "components/SoftButton";
import { useEffect, useState } from "react";

function Header({
  orderId,
  date,
  shipmentStatus: shipmentStatusProp,
  paymentStatus: paymentStatusProp,
  publicOrderId,
  onOrderUpdate: onOrderUpdateProp,
}) {
  const [shipmentStatus, setShipmentStatus] = useState(shipmentStatusProp);
  const [paymentStatus, setPaymentStatus] = useState(paymentStatusProp);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);

  useEffect(() => {
    setShipmentStatus(shipmentStatusProp);
  }, [shipmentStatusProp]);

  useEffect(() => {
    setPaymentStatus(paymentStatusProp);
  }, [paymentStatusProp]);

  useEffect(() => {
    const isShipmentStatusUpdated = shipmentStatus && shipmentStatus.value !== shipmentStatusProp.value;
    const isPaymentStatusUpdated = paymentStatus && paymentStatusProp.value !== paymentStatus.value;
    setIsSaveButtonEnabled(isShipmentStatusUpdated || isPaymentStatusUpdated);
  }, [shipmentStatus, shipmentStatusProp, paymentStatus, paymentStatusProp]);

  const onOrderUpdate = () => {
    const isShipmentStatusUpdated = shipmentStatus && shipmentStatus.value !== shipmentStatusProp.value;
    const isPaymentStatusUpdated = paymentStatus && paymentStatusProp.value !== paymentStatus.value;

    const updatedOrderData = { orderId };

    if (isShipmentStatusUpdated) {
      updatedOrderData.shipmentStatus = shipmentStatus;
    }

    if (isPaymentStatusUpdated) {
      updatedOrderData.paymentStatus = paymentStatus;
    }

    onOrderUpdateProp(updatedOrderData);
  };

  return (
    <SoftBox display="flex" justifyContent={{ xs: "flex-start", md: "space-between" }} alignItems={{ xs: "flex-start", md: "center" }} flexDirection={{ xs: "column", md: "row" }}>
      <SoftBox>
        <SoftBox mb={1}>
          <SoftTypography variant="h6" fontWeight="medium">
            Detalji narudžbe
          </SoftTypography>
        </SoftBox>
        <SoftTypography component="p" variant="button" fontWeight="regular" color="text">
          Broj narudžbe:&nbsp; <SoftTypography component="span" sx={{ fontWeight: "bold", fontSize: "14px", color: "#000" }}>{`${publicOrderId}`}</SoftTypography>
        </SoftTypography>
        <SoftTypography component="p" variant="button" fontWeight="regular" color="text">
          Datum: &nbsp;<SoftTypography component="span" sx={{ fontSize: "14px", color: "#000" }}>{`${date}`}</SoftTypography>
        </SoftTypography>
      </SoftBox>
      <SoftBox 
        display="flex" 
        gap="1rem"
        alignItems="flex-end"
      >
        <SoftBox maxWidth="12rem">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Status narudžbe
          </SoftTypography>
          <SoftSelect
            caption={"Status narudžbe"}
            value={shipmentStatus}
            options={shipmentStatusSelectData}
            onChange={setShipmentStatus}
          />
        </SoftBox>
        <SoftBox maxWidth="12rem">
          <SoftTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            Status plaćanja
          </SoftTypography>
          <SoftSelect
            caption={"Status plaćanja"}
            value={paymentStatus}
            options={paymentStatusSelectData}
            onChange={setPaymentStatus}
          />
        </SoftBox>
        <SoftButton
          disabled={!isSaveButtonEnabled}
          variant="gradient" 
          color="success"
          sx={{
            height: 'fit-content',
          }}
          onClick={onOrderUpdate}
        >
          Sačuvaj
        </SoftButton>
      </SoftBox>
    </SoftBox>
  );
}

Header.defaultProps = {
  orderId: -1,
  date: "1.1.1970",
  shipmentStatus: undefined,
  paymentStatus: undefined,
  onChangePaymentStatus: () => { },
  onChangeShipmentStatus: () => { },
  onOrderUpdate: () => { },
  publicOrderId: "",
};

Header.propTypes = {
  orderId: PropTypes.number,
  date: PropTypes.string,
  shipmentStatus: PropTypes.object || undefined,
  paymentStatus: PropTypes.object || undefined,
  onChangePaymentStatus: PropTypes.func,
  onChangeShipmentStatus: PropTypes.func,
  onOrderUpdate: PropTypes.func,
  publicOrderId: PropTypes.string,
};


export default Header;
