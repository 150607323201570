import { Divider, Grid } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import {
  useSoftUIController,
  updateProduct,
  removeCartItem,
  setCoupon
} from "context";
import SoftBox from "components/SoftBox";
import ProductItem from "pages/user/configurator/product-item";
import PropTypes from "prop-types";
import { get, SERVER_URL_PREFIX } from "api";
import FormField from "pages/user/checkout/checkout-form/form-field";
import { Formik } from "formik";
import SoftButton from "components/SoftButton";
import { useEffect, useState } from "react";

const CheckoutProducts = ({ readOnly, couponPreview }) => {
  const [controller, dispatch] = useSoftUIController();
  const { cartItems, selectedCurrency, checkoutValues, countries, coupon } = controller;

  const [couponLoading, setCouponLoading] = useState(false);
  const [couponError, setCouponError] = useState(null);

  const [basePrice, setBasePrice] = useState(0);
  const [specialOffersPrice, setSpecialOffersPrice] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [couponDiscount, setCouponDiscount] = useState(0);

  useEffect(() => {
    setBasePrice(calculateBasePrice());
  }, [cartItems]);

  useEffect(() => {
    setSpecialOffersPrice(calculateTotalWithSpecialOffers());
  }, [basePrice]);

  useEffect(() => {
    setSubTotal(calculateSubTotal());
  }, [specialOffersPrice, couponDiscount]);

  useEffect(() => {
    setTotal(calculateTotal());
  }, [subTotal, checkoutValues.country]);

  useEffect(() => {
    setCouponDiscount(calculateCouponDiscount());
  }, [coupon, cartItems, specialOffersPrice]);

  const calculateSubTotal = () => {
    return cartItems.reduce((accumulator, currentValue) => accumulator + currentValue.calculatedPrice, 0) - couponDiscount;
  }

  const calculateCouponDiscount = () => {
    if (!coupon) return 0;
    if (coupon.type === "percentage") {
      return specialOffersPrice * coupon.discount / 100;
    } else {
      return coupon.discount;
    }
  }

  const handleIncreaseQuantity = (productId) => {
    const product = cartItems.find(item => item.id === productId);
    get(`${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${product.quantity + 1}&currency=${selectedCurrency.value.toUpperCase()}`)
      .then(response => response.json())
      .then(data => {
        updateProduct(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice, quantity: product.quantity + 1 });
      })
  }

  const handleDecreaseQuantity = (productId) => {
    const product = cartItems.find(item => item.id === productId);
    get(`${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${product.quantity - 1}&currency=${selectedCurrency.value.toUpperCase()}`)
      .then(response => response.json())
      .then(data => {
        updateProduct(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice, quantity: product.quantity - 1 });
      })
  }

  const handleRemoveItem = (productId) => {
    removeCartItem(dispatch, productId);
  }

  const calculateTotal = () => {
    return subTotal + getCorrespondingShippingPrice();
  }

  const calculateTotalWithSpecialOffers = () => {
    return cartItems.reduce((accumulator, currentValue) => accumulator + currentValue.calculatedPrice, 0);
  }

  const calculateBasePrice = () => {
    return cartItems.reduce((accumulator, currentValue) => accumulator + currentValue.realPrice, 0)
  }

  const getCorrespondingCurrency = () => {
    if (!checkoutValues.country || countries.length === 0) return "";
    const country = countries.find(c => c.shortName.toLowerCase() === checkoutValues.country.toLowerCase());
    return country.currency;
  }

  const getCorrespondingShippingPrice = () => {
    if (!checkoutValues.country || countries.length === 0) return "";
    const country = countries.find(c => c.shortName.toLowerCase() === checkoutValues.country.toLowerCase());
    return country.shippingPrice;
  }

  return (
    <Grid container>
      <Grid item xs={12} xl={9} xxl={8}>
        {/* <SoftBox pb={3}>
            <SoftTypography variant="h6" component="span">Proizvodi</SoftTypography>
          </SoftBox> */}
        {
          cartItems && cartItems.length !== 0 ?
            <SoftBox maxWidth={{ xs: "auto", lg: "600px" }}>
              <SoftBox display="flex" flexDirection="column" gap="3rem">
                {cartItems.map((item, i) => (
                  <ProductItem
                    key={i}
                    id={item.id}
                    name={item.name}
                    price={String(item.unitPrice)}
                    unitQuantity={String(item.quantity)}
                    currency={selectedCurrency.value.toUpperCase()}
                    realPrice={item.realPrice}
                    calculatedPrice={item.calculatedPrice}
                    quantity={item.quantity}
                    img={item.img}
                    handleIncreaseQuantity={handleIncreaseQuantity}
                    handleDecreaseQuantity={handleDecreaseQuantity}
                    handleClose={handleRemoveItem}
                    readOnly={readOnly}
                  />
                ))}
              </SoftBox>
              {(basePrice - specialOffersPrice) !== 0 &&
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" mt="1.5rem">
                  <SoftBox>
                    <SoftTypography sx={({ typography }) => ({
                      fontSize: typography.size.sm,
                      fontWeight: typography.fontWeightRegular,
                    })}>
                      Ostvarili ste popust od&nbsp;
                      <SoftTypography
                        component="span"
                        sx={({ typography }) => ({
                          fontSize: typography.size.sm,
                          fontWeight: typography.fontWeightBold,
                          color: basePrice - specialOffersPrice > 0 ? "success.focus" : '#98999e',
                        })}>{basePrice - specialOffersPrice} {selectedCurrency.label}</SoftTypography>
                      &nbsp;na izabranu količinu.
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              }
              {couponDiscount !== 0 && <Divider sx={{ mt: "1.5rem" }} />}
              {!couponPreview &&
                <Formik
                  initialValues={{ coupon: coupon ? coupon.code : "" }}
                  onSubmit={(values, { setSubmitting, setFieldError }) => {
                    // check coupon validity on server
                    setCouponLoading(true);
                    get(`${SERVER_URL_PREFIX}/api/web/coupon/validate/${values.coupon}`)
                      .then(response => {
                        if (response.status === 200) {
                          return response.json()
                        } else {
                          setCoupon(dispatch, null);
                          throw new Error('Coupon not valid');
                        }
                      })
                      .then(data => {
                        setCoupon(dispatch, data);
                        setCouponError(null);
                      })
                      .catch(err => {
                        setCoupon(dispatch, null);
                        setCouponError(err.message);
                      })
                      .finally(() => setCouponLoading(false));

                    setSubmitting(false);
                  }}
                >
                  {({ values, handleSubmit, handleChange }) => {
                    return (
                      <SoftBox display="flex" alignItems="start" gap="5px">
                        <SoftBox display="flex" flexDirection="column" gap="5px" width="100%">
                          <FormField
                            type="text"
                            name="coupon"
                            label="Unesite kupon"
                            placeholder="Unesite kupon"
                            value={values.coupon}
                            sx={{ width: "100%" }}
                            onChange={e => {
                              handleChange(e);
                              setCouponError(null);
                            }}
                          />
                          {coupon &&
                            <SoftTypography variant="caption" sx={{
                              color: "success.focus",
                            }}>
                              Kupon je uspješno primijenjen
                            </SoftTypography>}
                          {couponError &&
                            <SoftTypography variant="caption" sx={{
                              color: "error.main",
                            }}>
                              Kupon nije validan
                            </SoftTypography>}
                        </SoftBox>
                        <SoftButton
                          variant="gradient"
                          color="success"
                          type="submit"
                          onClick={handleSubmit}
                          fullWidth
                          disabled={couponLoading || !values.coupon}
                          sx={{ width: "fit-content", marginTop: "32px" }}
                        >
                          Potvrdi
                        </SoftButton>

                      </SoftBox>
                    )
                  }}
                </Formik>
              }
              {couponDiscount !== 0 &&
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" mt="1.5rem">
                  <SoftBox>
                    <SoftTypography sx={({ typography }) => ({
                      fontSize: typography.size.sm,
                      fontWeight: typography.fontWeightRegular,
                    })}>
                      Popust od kupona {coupon ?
                        <SoftTypography
                          component="span"
                          sx={({ typography }) => ({
                            fontSize: typography.size.sm,
                            fontWeight: typography.fontWeightBold,
                          })}>&nbsp; {coupon.code}</SoftTypography> :
                        ""}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox display="flex">
                    <SoftTypography sx={({ typography }) => ({
                      fontSize: typography.size.xs,
                      fontWeight: typography.fontWeightBold,
                      // fontWeight: typography.fontWeightBold,
                      color: coupon ? "success.focus" : '#98999e',
                    })}>
                      {couponDiscount > 0 ? "-" : ""} {couponDiscount} {selectedCurrency.label}
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              }
              <Divider sx={{ mt: "1.5rem" }} />
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" mt="1.5rem">
                <SoftBox>
                  <SoftTypography variant="body2" sx={{color: "#000", fontWeight: "bold"}}>
                    Iznos narudžbe
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" gap="0.5rem">
                  {basePrice > subTotal &&
                    <SoftTypography sx={({ typography }) => ({
                      fontSize: typography.size.md,
                      fontWeight: typography.fontWeightRegular,
                      color: '#000',
                      fontStyle: 'italic',
                      textDecoration: "line-through"
                    })}>
                      {basePrice} {selectedCurrency.label}
                    </SoftTypography>
                  }
                  <SoftTypography sx={({ typography }) => ({
                    fontSize: typography.size.md,
                    fontWeight: typography.fontWeightBold,
                    color: '#000',
                  })}>
                    &nbsp;&nbsp;{subTotal} {selectedCurrency.label}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" mt="1.5rem">
                <SoftBox>
                  <SoftTypography sx={({ typography }) => ({
                    fontSize: typography.size.sm,
                    fontWeight: typography.fontWeightRegular,
                  })}>
                    Dostava
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex">
                  <SoftTypography sx={({ typography }) => ({
                    fontSize: typography.size.sm,
                    fontWeight: typography.fontWeightBold,
                    color: '#98999e',
                  })}>
                    &nbsp;&nbsp;{getCorrespondingShippingPrice()} {selectedCurrency.label}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              <Divider sx={{ mt: "1.5rem" }} />
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" mt="1.5rem">
                <SoftBox>
                  <SoftTypography variant="body2" sx={{color: "#000", fontWeight: "bold"}}>
                    Ukupno
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" gap="0.5rem">
                  <SoftTypography sx={({ typography }) => ({
                    fontSize: typography.size.md,
                    fontWeight: typography.fontWeightBold,
                    color: '#000',
                  })}>
                    &nbsp;&nbsp;{total} {selectedCurrency.label}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              {checkoutValues.country && <SoftBox display="flex" sx={({ palette }) => ({
                backgroundColor: palette.grey[200],
                p: "1rem",
                borderRadius: "1rem",
                mt: "3rem",
                justifyContent: "center"
              })}>
                <SoftTypography sx={({ typography }) => ({
                  fontSize: typography.size.sm,
                  textAlign: "center"
                })}>
                  Za izabranu drzavu placanje se moze izvrsiti iskljucivo u &nbsp;
                  <SoftTypography
                    component="span"
                    sx={({ typography }) => ({
                      fontSize: typography.size.sm,
                      fontWeight: typography.fontWeightBold,
                    })}>
                    {getCorrespondingCurrency()}
                  </SoftTypography>
                </SoftTypography>

              </SoftBox>
              }
            </SoftBox>
            :
            <SoftTypography variant="caption">Trenutno nemate proizvoda u korpi</SoftTypography>
        }
      </Grid>
    </Grid >
  )
}

CheckoutProducts.defaultProps = {
  readOnly: false,
  couponPreview: false,
};

CheckoutProducts.propTypes = {
  readOnly: PropTypes.bool,
  couponPreview: PropTypes.bool,
};

export default CheckoutProducts;