import { Grid, Icon } from "@mui/material";
import PageLayout from "pages/layout/page-layout";
import Product from "pages/user/products/product";
import { addCartItem, updateProduct } from "context";
import { useSoftUIController } from "context";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { get, SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";
import { useDocumentTitle } from "hooks/use-document-title";
import ProductSkeleton from "./product/skeleton";

const Products = (props) => {
  useDocumentTitle("Mama za pet - Prodavnica");

  const [controller, dispatch] = useSoftUIController();
  const { cartItems, selectedCurrency } = controller;
  const [products, setProducts] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);

  useEffect(() => {
    setIsLoadingProducts(true);
    get(`${SERVER_URL_PREFIX}/api/web/products`)
      .then(response => response.json())
      .then(data => {
        setProducts(data)
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  }, []);

  const handleAddToCart = (product) => {
    const currentProduct = cartItems.find(item => item.id === product.id);
    get(`${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${currentProduct ? currentProduct.quantity + 1 : product.quantity}&currency=${selectedCurrency.value.toUpperCase()}`)
      .then(response => response.json())
      .then(data => {
        if (!currentProduct) {
          addCartItem(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice });
        } else {
          updateProduct(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice, quantity: currentProduct.quantity + 1 });
        }
      })
  }

  return (
    <PageLayout>
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18rem"
        justifyContent="space-between"
        flexDirection={{ xs: "column-reverse", lg: "row" }}
        px={{ xs: "0", lg: "6rem" }}
        sx={{
          backgroundImage: { xs: "none", lg: "linear-gradient(rgba(250, 250, 250, 0.5), rgba(250, 250, 250, 0.9))" },
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        <SoftBox sx={{
          background: { xs: "linear-gradient(rgba(250, 250, 250, 0.5), rgba(250, 250, 250, 0.9))", lg: "transparent" },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: { xs: "center", lg: "flex-start" },
          py: { xs: "2rem", lg: "0" },
          px: { xs: "1rem", lg: "0" }
        }}>
          <SoftBox display="flex" alignItems="center" mb={2} sx={{ maxWidth: "30rem" }} >
            <SoftBox component="img" src={`${RESOURCES_SERVER_URL_PREFIX}/static/icons/nature-leaf-icon.png`} alt="List ikonica" width="2.5rem" mr="1rem" />
            <SoftTypography variant="h6" fontWeight="bold">
              100% Prirodni proizvodi
            </SoftTypography>
          </SoftBox>
          <SoftBox mb={2} sx={{ maxWidth: "30rem", textAlign: "center" }}>
            <SoftTypography variant="body2">
              <>
                U svakom pakovanju čekaju te zeolit i eterična ulja koja pomažu
                kod akni, ožiljaka, dermatitisa, ekcema i snažno regenerišu kožu.
              </>
            </SoftTypography>
          </SoftBox>
          <SoftButton
            component={Link}
            to="/aboutproducts"
            // target="_blank"
            rel="noreferrer"
            // variant="outlined"
            color="success"
          // circular
          >
            Pročitaj više &nbsp; <Icon>arrow_forward</Icon>
          </SoftButton>
        </SoftBox>
        <SoftBox px={{ xs: "1rem", md: "0" }}>
          {/* <SoftBox component="img" src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/products/header-postolje-ruza.webp`} alt="Zaglavlje proizvodi na postolju" sx={{ maxHeight: "22rem", width: { xs: "100%" } }} /> */}
          <SoftBox component="img" src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/header-postolje.webp`} alt="Zaglavlje proizvodi na postolju" sx={{ maxHeight: "22rem", width: { xs: "100%" } }} />
        </SoftBox>
      </SoftBox>
      <SoftBox display="flex" justifyContent="center" py="2rem">
        <SoftTypography variant="h4">
          SVI PROIZVODI
        </SoftTypography>
      </SoftBox>
      <Grid
        container
        spacing={1}
        sx={{
          rowGap: "5rem",
        }}
      >
        {
          isLoadingProducts ?
          Array.from(new Array(6)).map((_, index) => (
            <ProductSkeleton key={index} />
          )) : 
          products.map((product) => (
            <Product
              key={product.id}
              id={product.id.toString()}
              title={product.name}
              type={product.type}
              compositeProductItems={product.compositeProductItems}
              price={selectedCurrency.value === 'bam' ? product.unitPriceBam.toString() : product.unitPriceEur.toString()}
              currency={selectedCurrency}
              quantity={product.quantity}
              image={`${RESOURCES_SERVER_URL_PREFIX}${product.imageLocation}`}
              onAddToCart={handleAddToCart}
              specialOffers={product.specialOffers}
            />
          ))
        }
      </Grid>
    </PageLayout>
  )
}

export default Products;