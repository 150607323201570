import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ActionCell({ productId }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Pogledaj detalje" placement="top">
          <Link to={`/products/${productId}`}>
            <Icon color="secondary">visibility</Icon>
          </Link>
        </Tooltip>
      </SoftTypography>
      {/* <SoftBox mx={2}>
        <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
          <Tooltip title="Edit product" placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Delete product" placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </SoftTypography> */}
    </SoftBox>
  );
}

ActionCell.defaultProps = {
  productId: -1
};

ActionCell.propTypes = {
  productId: PropTypes.number
};

export default ActionCell;
