import { Divider, Grid, Icon, Link } from "@mui/material";
import { get, SERVER_URL_PREFIX } from "api";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import { useSoftUIController, setCountries } from "context";
import { useDocumentTitle } from "hooks/use-document-title";
import PageLayout from "pages/layout/page-layout";
import CheckoutForm from "pages/user/checkout/checkout-form";
import CheckoutProducts from "pages/user/checkout/checkout-products";
import { useEffect, useState } from "react";

const Checkout = (props) => {
  useDocumentTitle("Mama za pet - Checkout");

  const [controller, dispatch] = useSoftUIController();
  const { cartItems } = controller;
  const [submittedEvent, setSubmittedEvent] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    get(`${SERVER_URL_PREFIX}/api/web/countries`)
      .then(response => response.json())
      .then(data => {
        setCountries(dispatch, data);
      })
  }, [])

  return (
    <PageLayout>
      <Grid container>
        <Grid item xs={12}>
          <Divider sx={{ mt: "0" }} />
          <SoftBox display="flex" justifyContent="space-between" px="2rem">
            <SoftBox></SoftBox>
            <SoftBox>KREIRANJE NARUDŽBE</SoftBox>
            <SoftBox></SoftBox>
          </SoftBox>
          <Divider sx={{ mb: "0.1rem" }} />
          {cartItems.length !== 0 ?
            <Grid container>
              <Grid item xs={12} lg={6} sx={{ py: "2rem", display: "flex", justifyContent: "end" }}>
                <Grid container display="flex" justifyContent="end">
                  <Grid item xs={12} xl={9} xxl={8}>
                    <SoftBox display="flex" justifyContent="end" >
                      <CheckoutForm submittedEvent={submittedEvent} setFormErrors={setFormErrors} />
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} display={{ xs: 'block', lg: 'none' }}>
                <Divider sx={{ mb: "2rem" }} />
              </Grid>
              <Grid item xs={12} lg={6} sx={{ py: { xs: "0", lg: "5.5rem" }, px: "1rem" }} >
                <SoftBox display="flex" flexDirection="column" gap="1rem" >
                  <CheckoutProducts />
                  <Grid container>
                    <Grid item xs={12} xl={9} xxl={8} maxWidth={{ xs: "auto", lg: "600px !important" }}>
                      <SoftButton
                        variant="gradient"
                        color="success"
                        type="submit"
                        onClick={e => setSubmittedEvent(e)}
                        fullWidth
                        disabled={cartItems.length === 0}
                      >
                        Pregledaj narudžbu
                      </SoftButton>
                      {submittedEvent && Object.keys(formErrors).length > 0 && (
                        <SoftBox item xs={12} xl={9} xxl={8} maxWidth={{ xs: "auto", lg: "600px !important" }} m="1rem auto">
                          <SoftTypography variant="body2" color="error" textAlign="center">
                            Sva zathjevana polja moraju biti popunjena!
                          </SoftTypography>
                        </SoftBox>
                      )}
                    </Grid>
                  </Grid>
                </SoftBox>
              </Grid>
            </Grid>
            :
            <SoftBox display="flex" alignItems="center" width="100%" mt="2rem" flexDirection="column" gap="2rem">
              <SoftTypography variant="body2" color="text">Trenutno nemate proizvoda u korpi</SoftTypography>
              <SoftButton
                component={Link}
                href="/store"
                color="info"
                variant="gradient"
                sx={{ maxWidth: "20rem", minWidth: "12rem" }}
              >
                <Icon>shopping_bag</Icon>&nbsp; Posjeti prodavnicu
              </SoftButton>
            </SoftBox>
          }
        </Grid>
      </Grid>
    </PageLayout>
  )
}

export default Checkout;