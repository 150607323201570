import PropTypes from "prop-types";

import SoftTypography from "components/SoftTypography";

function PriceCell({ value, suffix, preffix }) {
  return (
    <div style={{
      textAlign: "right"
    }}>
      <SoftTypography variant="caption" fontWeight="bold" color="secondary">
        {value}
      </SoftTypography>
      <SoftTypography variant="caption" fontWeight="medium" sx={({ palette }) => ({
        fontSize: "0.6rem"
        // color: palette["black"].main,
      })}>
        &nbsp;&nbsp;{preffix}
      </SoftTypography>
    </div>
  );
}

PriceCell.defaultProps = {
  suffix: "",
  preffix: "",
};

PriceCell.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  preffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default PriceCell;
