const products = [
  { name: "Krema protiv bora", image: "imageA.png", sales: 60 },
  { name: "Zeolitna maska za lice", image: "imageB.png", sales: 35 },
  { name: "Serum za lice", image: "imageC.png", sales: 30 },
  { name: "All-In-One Set", image: "imageD.png", sales: 20 },
  { name: "Krema-Serum Set", image: "imageE.png", sales: 18 },
  { name: "Maska-Serum Set", image: "imageF.png", sales: 15 },
];

// Sort products by sales in descending order
products.sort((a, b) => b.sales - a.sales);

// Format the data for the chart
const productSalesMockData = {
  // labels: products.map(product => `<img src="${product.image}" alt="${product.name}" /> ${product.name}`),
  labels: products.map(product => product.name),
  datasets: [
    {
      label: "Sales by product",
      color: "dark",
      data: products.map(product => product.sales),
    },
  ],
};

export { productSalesMockData };