import { Divider, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import PageLayout from "pages/layout/page-layout";
import ProductDescription from "../about-products/product-description";
import ProductImage from "../about-products/product-image";
import SocialFollowComponent from "pages/user/components/social-follow-component";
import { useDocumentTitle } from "hooks/use-document-title";
import { RESOURCES_SERVER_URL_PREFIX } from "api";

const About = () => {
  useDocumentTitle("Mama za pet - O meni");

  return (
    <PageLayout>
      <Grid container>
        <Grid item xs={12}>
          <Divider sx={{ mt: "0" }} />
          <SoftBox display="flex" justifyContent="space-between" px="2rem">
            <SoftBox></SoftBox>
            <SoftBox>
              {/* <SoftBox>Pomažem damama da ubrzaju kućne poslove, ali i da ne bacaju novac na kreme koje im samo skupljaju prašinu.</SoftBox> */}
              <SoftBox
                component="img"
                src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/potpis.png`}
                alt="Natalija Todorovic potpis"
                sx={{ height: "2.4rem", px: "0.4rem" }}
              />
            </SoftBox>
            <SoftBox></SoftBox>
          </SoftBox>
          <Divider sx={{ mb: "0" }} />
        </Grid>
      </Grid>
      <Grid container >
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/mamazapet-krupni-plan.webp`} />
        <Grid item lg={6} md={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: { xs: "2rem 1rem", md: "3rem" } }}>
          <ProductDescription
            title="O meni"
            textArray={[
              "Zdravo. Ja sam Natalija Todorović na Instagramu poznatija kao Mama za pet.",
              "Zajedno sa čika Slavkom Crnićem i Institutom Vinis u Banjaluci napravila sam kremu protiv bora koja uz pomoć zeolita i 12 biljnih eteričnih ulja njeguje lice, područje oko očiju i dekolte.",
            ]}
          />
        </Grid>
      </Grid>
      <Grid container display="flex" flexDirection={{ xs: "column-reverse", lg: "row" }}>
        <Grid item lg={6} md={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", p: { xs: "2rem 1rem", md: "3rem" } }}>
          <ProductDescription
            title="Otkud ja u svijetu kozmetike?"
            textArray={[
              "U Banjaluci, gradu u kojem sam rođena, već decenijama živi čika Slavko Crnić koji iza sebe ima preko 250 prirodnih proizvoda. Upoznala sam ga sasvim slučajno, jedno poslijepodne dok sam čekala kćerku da završi sa školom. Do sada sam u njegovom Virtuelnom naučno-istraživačkom institutu Vinis naučila kako da pripremam ukusne sirove obroke.Kozmetiku punu hemije zamijenila sam pastom za zube sa zeolitom, mirisom sa eteričnim uljima, kao i prirodnim kremama za lice i tijelo. Ne mogu vam opisati koliko sam srećna zbog toga.",
              "Pošto imam jako osjetljivo lice, 36 godina a na područje oko očiju nisam nanosila ništa – jer mi je smetalo – zamolila sam čika Slavka da specijalno za mene napravi proizvod s kojim ću njegovati bore, područje oko očiju i dekolte. I da, ako je ikako moguće, ne ostavlja onaj ružni masni sjaj (kao sve moje dosadašnje maze za lice). Slijedeći dan dočekalo me je pakovanje sa zeolitom i 12 biljnih eteričnih ulja. Pretpostavićete da je ispunilo sva moja očekivanja, čak i više od toga. A onda sam sasvim spontano odlučila da to bude krema sa mojim potpisom i da je predstavim drugim damama na Instagramu."
            ]}
          />
        </Grid>
        <ProductImage image={`${RESOURCES_SERVER_URL_PREFIX}/static/images/mamazapet-maze-kremu-krupni-plan.webp`} />
      </Grid>
      <Grid container>
        {/* <Grid item sm={12}>
          <Divider sx={{ mt: "0" }} />
          <SoftBox display="flex" justifyContent="space-between" px="2rem">
            <SoftBox></SoftBox>
            <SoftBox>
              <SoftBox>Mama za pet</SoftBox>
              <SoftBox component="img" src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/potpis.png`} alt="slika" sx={{ height: "2rem", width: "8rem", px: "0.4rem" }} />
            </SoftBox>
            <SoftBox></SoftBox>
          </SoftBox>
          <Divider sx={{ mb: "0" }} />
        </Grid> */}
      </Grid>
      {/* Text divider */}
      <SoftBox display="flex" justifyContent="space-between" px="2rem" pt={{ xs: "2rem", lg: "5rem" }}>
        <SoftBox></SoftBox>
        <SoftBox>
          <SocialFollowComponent />
        </SoftBox>
        <SoftBox></SoftBox>
      </SoftBox>

    </PageLayout >
  )
}

export default About;