import React from 'react';
import { Icon } from '@mui/material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

const Sales = ({ quantity, salesPrice, originalPrice, currency }) => {
  return (
    <SoftBox display="flex"
      sx={{
        backgroundColor: "#f7f7f7",
        px: "0.4rem",
        py: "0.2rem",
        borderRadius: "0.5rem",
        maxWidth: "fit-content"
      }}>
      <Icon color="success">star</Icon>
      <SoftBox display="flex">
        <SoftTypography sx={({ typography, borders }) => ({
          fontSize: typography.size.xs,
          fontWeight: typography.fontWeightBold,
          color: '#98999e',
        })}>&nbsp;{quantity} za {salesPrice} {currency}&nbsp;&nbsp;</SoftTypography>
        <SoftTypography sx={({ typography, borders }) => ({
          fontSize: typography.size.xs,
          fontWeight: typography.fontWeightRegular,
          color: '#98999e',
          fontStyle: 'italic',
          textDecoration: "line-through"
        })}>{originalPrice} {currency}</SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

Sales.propTypes = {
  quantity: PropTypes.number,
  salesPrice: PropTypes.number,
  originalPrice: PropTypes.number,
  currency: PropTypes.string
};

Sales.defaultProps = {
  quantity: 0,
  salesPrice: 0,
  originalPrice: 0,
  currency: "BAM"
};

export default Sales;