import PropTypes from "prop-types";

import SoftAvatar from "components/SoftAvatar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const ReviewItem = ({ image, userName, userTitle, text }) => {
  return (
    <SoftBox sx={{ minWidth: "35rem", backgroundColor: "#fff", borderRadius: "2rem", px: "2rem", py: "1rem", height: "100%" }}>
      <SoftBox display="flex" alignItems="center" sx={{ 'gap': '1rem' }} mb={2} >
        <SoftAvatar
          src={image}
          size="md"
        />
        <SoftBox display="flex" flexDirection="column">
          <SoftTypography variant="h5" fontWeight="bold">
            {userName}
          </SoftTypography>
          <SoftTypography sx={({ typography: { size, fontWeightMedium }, palette: { grey } }) => (
            {
              fontSize: size.xs,
              fontWeight: fontWeightMedium,
              color: grey[600]
            })}>
            {userTitle}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox mb={2}>
        <SoftTypography sx={({ typography: { size, fontWeightMedium }, palette: { grey } }) => (
          {
            fontSize: size.sm,
            fontWeight: fontWeightMedium,
            color: grey[600],
            textAlign: "justify"
          })}>
          {text}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  )
}

ReviewItem.defaultProps = {
  image: "",
  userName: "Nickname",
  userTitle: "Kupac",
  text: "Tekst nije učitan",
};

ReviewItem.propTypes = {
  image: PropTypes.string,
  userName: PropTypes.string,
  userTitle: PropTypes.string,
  text: PropTypes.string,
};

export default ReviewItem;