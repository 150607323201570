import { Accordion, AccordionSummary, AccordionDetails, Typography, Icon } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SoftBox from 'components/SoftBox';
import { useState } from 'react';
import SoftTypography from 'components/SoftTypography';

const faqData = [
  {
    question: "Kako koristiti kremu protiv bora?",
    answer: "Kremu nanesite na čistu kožu lica i vrata, izbegavajući područje oko očiju. Lagano masirajte dok se krema potpuno ne upije. Koristite ujutru i uveče za najbolje rezultate."
  },
  {
    question: "Koji su sastojci kreme protiv bora?",
    answer: "Naša krema sadrži hijaluronsku kiselinu, vitamin C, kolagen, eterična ulja smilja, australijskog čajevca, bergamota, eukaliptusa, ruže i još sedam ulja koja njeguju kožu."
  },
  {
    question: "Da li je krema pogodna za sve tipove kože?",
    answer: "Da, naša krema je dermatološki testirana i pogodna za sve tipove kože, uključujući osjetljivu kožu sklonu iritacijama."
  },
  {
    question: "Koliko često treba koristiti kremu?",
    answer: "Preporučujemo korišćenje kreme dva puta dnevno, ujutru i uveče, za optimalne rezultate."
  },
  {
    question: "Da li krema sadrži parabene ili sulfate?",
    answer: "Ne, naša krema ne sadrži parabene, sulfate niti veštačke mirise. Pruža prirodnu njegu bez štetnih sastojaka."
  }
];

const FAQSection = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <SoftBox sx={{ px: "2rem" }}>
      {/* <Typography variant="h4" sx={{ mb: "2rem", textAlign: "center" }}>Često postavljana pitanja</Typography> */}
      {faqData.map((item, index) => (
        <Accordion
          key={index}
          expanded={expanded === `panel${index}`}
          onChange={handleChange(`panel${index}`)}
          sx={{
            mb: "1rem",
            borderRadius: "0.5rem",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            sx={{
              backgroundColor: "#f0f0f0",
              borderRadius: "0.5rem",
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
          >
            <SoftTypography variant="h6" fontWeight="700" color="black">{item.question}</SoftTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "#fff", borderRadius: "0.5rem" }}>
            <SoftTypography variant="body2">{item.answer}</SoftTypography>
          </AccordionDetails>
        </Accordion>
      ))}
    </SoftBox>
  );
};

export default FAQSection;