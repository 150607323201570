import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { Icon } from "@mui/material";
import selectData from "pages/user/checkout/checkout-form/data/selectData";
import { useSoftUIController } from "context";
import { useNavigate } from "react-router-dom";

function CheckoutDetails(props) {
  const [controller] = useSoftUIController();
  const { checkoutValues } = controller;
  const navigate = useNavigate();

  const handleBack = () => navigate("/checkout");

  return (
    <Grid container display="flex" justifyContent="flex-end">
      <Grid item xs={12} xl={9} xxl={8}>
        <SoftBox component="div" pb={3} px={{xs: "1rem", lg: "3rem"}} maxWidth={{ xs: "auto", lg: "600px"}} ml="auto">
          <SoftBox width="100%" display="flex" flexDirection="column" lineHeight={1} mt="2rem" sx={{ backgroundColor: "#fafafa", borderRadius: "1rem", p: "1rem" }}>
            <SoftBox mb={2}>
              <SoftTypography variant="h5" fontWeight="medium">
                Podaci o kupcu
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Ime i prezime:&nbsp;&nbsp;&nbsp;
                <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  {checkoutValues.fullName}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Email adresa:&nbsp;&nbsp;&nbsp;
                <SoftTypography variant="button" fontWeight="medium">
                  {checkoutValues.email}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Broj telefona:&nbsp;&nbsp;&nbsp;
                <SoftTypography variant="button" fontWeight="medium">
                  {checkoutValues.phoneNumber}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox width="100%" display="flex" flexDirection="column" lineHeight={1} mt="2rem" sx={{ backgroundColor: "#fafafa", borderRadius: "1rem", p: "1rem" }}>
            <SoftBox mb={2}>
              <SoftTypography variant="h5" fontWeight="medium">
                Adresa za dostavu
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Ulica:&nbsp;&nbsp;&nbsp;
                <SoftTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  {checkoutValues.streetAndNumber}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Poštanski broj:&nbsp;&nbsp;&nbsp;
                <SoftTypography variant="button" fontWeight="medium">
                  {checkoutValues.postalCode}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Grad:&nbsp;&nbsp;&nbsp;
                <SoftTypography variant="button" fontWeight="medium">
                  {checkoutValues.city}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
            <SoftBox mb={1} lineHeight={0}>
              <SoftTypography variant="caption" color="text">
                Država:&nbsp;&nbsp;&nbsp;
                <SoftTypography variant="button" fontWeight="medium">
                  {selectData.country.find(c => c.value === checkoutValues.country).label.props.name}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftButton
            variant="text"
            color="secondary"
            onClick={handleBack}
            sx={{alignSelf: "flex-start", mt: "1rem"}}
          >
            <Icon>chevron_left</Icon>Promijeni podatke
          </SoftButton>
        </SoftBox>
      </Grid>
    </Grid>
  );
}

CheckoutDetails.defaultProps = {
};

CheckoutDetails.propTypes = {
};

export default CheckoutDetails;
