import { Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import PropTypes from "prop-types";

const ProductImage = ({ image }) => (
  <Grid
    item
    lg={6}
    xs={12}
    sx={{ position: "relative" }}
    minHeight={{ xs: "23rem", sm: "30rem", lg: "35rem", xxl: "50rem"}}
  >
    <SoftBox component="img" src={image} alt="Proizvod" sx={{
      height: "100%",
      width: "100%",
      objectFit: "cover",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    }} />
  </Grid>
)

ProductImage.defaultProps = {
  image: ""
};

ProductImage.propTypes = {
  image: PropTypes.string,
};

export default ProductImage;