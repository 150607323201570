import * as React from 'react';

import PropTypes from "prop-types";

import hexToRgb from 'assets/theme/functions/hexToRgb';

import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import TagFacesIcon from '@mui/icons-material/TagFaces';

import SoftBox from 'components/SoftBox';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipsArray({ data: chipData, handleDelete }) {
  const handleDeleteChip = (chipToDelete) => () => {
    handleDelete(chipToDelete);
  };

  return (
    <SoftBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        listStyle: 'none',
      }}
      component="ul"
    >
      {chipData.map((data) => {
        let icon;

        if (data.label === 'React') {
          icon = <TagFacesIcon />;
        }

        return (
          <ListItem key={data.key}>
            <Chip
              icon={icon}
              label={data.label}
              onDelete={handleDeleteChip(data)}
              sx={({ palette: { success } }) => ({ background: `rgba(${hexToRgb(success.main)}, 0.1)`, border: `1px solid ${success.main}` })}
            />
          </ListItem>
        );
      })}
    </SoftBox>
  );
}

ChipsArray.defaultProps = {
  data: [],
  handleDelete: () => { }
};

ChipsArray.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  handleDelete: PropTypes.func
};
