import { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import gradientChartLine from "assets/theme/functions/gradientChartLine";
import configs from "pages/admin/dashboard/components/charts/line-charts/gradient-line-chart/configs";
import colors from "assets/theme/base/colors";
import moment from "moment";

function GradientLineChart({ title, description, height, chart, rangeLabel }) {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});
  const { data, options } = chartData;

  useEffect(() => {
    const chartDatasets = chart.datasets
      ? chart.datasets.map((dataset, index) => ({
        ...dataset,
        tension: 0.4,
        pointRadius: 0,
        borderWidth: index === 1 ? 2 : 3, // Make the secondary line less thick
        borderColor: index === 1 ? colors.grey[300] : (colors[dataset.color] ? colors[dataset.color || "dark"].main : colors.dark.main), // Lighter grey color for secondary line
        fill: true,
        maxBarThickness: 6,
        backgroundColor: gradientChartLine(
          chartRef.current.children[0],
          colors[dataset.color] ? colors[dataset.color || "dark"].main : colors.dark.main
        ),
        borderDash: index === 1 ? [5, 5] : [], // Make the secondary line dashed
      }))
      : [];

    const chartOptions = {
      ...configs(chart.labels || [], chartDatasets).options,
      plugins: {
        tooltip: {
          callbacks: {
            title: (tooltipItems) => {
              const date = tooltipItems[0].label;
              const currentDate = moment(date, "Do MMM").format("Do MMM YYYY");
              const comparedDate = moment(date, "Do MMM").subtract(1, 'year').format("Do MMM YYYY");
              return `${currentDate} \nu odnosu na: ${comparedDate}`; // Include compared date in the title
            },
          },
        },
      },
    };

    setChartData({ data: configs(chart.labels || [], chartDatasets).data, options: chartOptions });
  }, [chart]);

  const renderChart = (
    <SoftBox p={2}>
      {title || description ? (
        <SoftBox display="flex" justifyContent="space-between">
          <SoftBox>
            <SoftBox px={description ? 1 : 0} pt={description ? 1 : 0}>
              {title && (
                <SoftBox mb={1}>
                  <SoftTypography variant="h6">{title}</SoftTypography>
                </SoftBox>
              )}
              <SoftBox mb={2}>
                <SoftTypography component="div" variant="button" fontWeight="regular" color="text">
                  {description}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox>
            {rangeLabel && (
              <SoftBox width="100%" textAlign="right" lineHeight={1} display="flex" justifyContent="end" mt="0.7rem">
                <SoftTypography
                  variant="caption"
                  color="secondary"
                >
                  {rangeLabel}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
      ) : null}
      <SoftBox ref={chartRef} sx={{ height: { xs: '220px !important', md: `${height} !important` } }}>
        <Line data={data} options={options} />
      </SoftBox>
    </SoftBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of GradientLineChart
GradientLineChart.defaultProps = {
  title: "",
  description: "",
  // height: "19.125rem",
};

// Typechecking props for the GradientLineChart
GradientLineChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default GradientLineChart;