import AboutProducts from "pages/user/about-products";
import Checkout from "pages/user/checkout";
import CheckoutPreview from "pages/user/checkout-preview";
import Home from "pages/user/home";
import ProductDetails from "pages/user/product-details";
import Products from "pages/user/products";
import ThankYou from "pages/user/thank-you";
import About from "pages/user/about";
import KremaProtivBora from "pages/user/product-pages/krema-protiv-bora";
import ZeolitnaMaskaZaLice from "pages/user/product-pages/zeolitna-maska-za-lice";
import Serum from "pages/user/product-pages/serum";
import Reviews from "pages/user/reviews";
import ReviewSurvey from "pages/user/review-survey";
import AllInOneSet from "pages/user/product-pages/all-in-one-set";
import CookiesPolicy from "pages/user/cookies-policy";
import PrivacyPolicy from "pages/user/privacy-policy";
import TermsAndConditions from "pages/user/terms-and-conditions";
import KremaSerumSet from "pages/user/product-pages/krema-serum-set";
import MaskaSerumSet from "pages/user/product-pages/maska-serum-set";

const userRoutes = [
  {
    name: "Početna",
    key: "home",
    route: "/",
    component: <Home />,
    noCollapse: true,
  },
  {
    name: "Prodavnica",
    key: "store",
    route: "/store",
    component: <Products />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Kreme i maske",
    key: "kreme",
    icon: <img src="/icons/menu/kreme.png" className="sub-menu-group-icon" />,
    collapse: [
      {
        name: "Krema protiv bora",
        key: "krema-protiv-bora",
        route: "/aboutproducts/krema-protiv-bora",
        component: <KremaProtivBora />,
      },
      {
        name: "Zeolitna maska za lice",
        key: "zeolitna-maska-za-lice",
        route: "/aboutproducts/zeolitna-maska-za-lice",
        component: <ZeolitnaMaskaZaLice />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Serumi",
    key: "serumi",
    icon: <img src="/icons/menu/serumi.png" className="sub-menu-group-icon" />,
    collapse: [
      {
        name: "Serum za lice",
        key: "about-products",
        route: "/aboutproducts/serum-za-lice",
        component: <Serum />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Setovi",
    key: "setovi",
    icon: <img src="/icons/menu/setovi.png" className="sub-menu-group-icon" />,
    collapse: [
      {
        name: "All-In-One Set",
        key: "all-in-one-set",
        route: "/aboutproducts/all-in-one-set",
        component: <AllInOneSet />,
      },
      {
        name: "Krema-Serum Set",
        key: "krema-serum-set",
        route: "/aboutproducts/krema-serum-set",
        component: <KremaSerumSet />,
      },
      {
        name: "Maska-Serum Set",
        key: "maska-serum-set",
        route: "/aboutproducts/maska-serum-set",
        component: <MaskaSerumSet />,
      },
    ],
  },
  {
    name: "O Proizvodima",
    key: "about-products",
    route: "/aboutproducts",
    component: <AboutProducts />,
    noCollapse: true,
  },
  {
    name: "Recenzije",
    key: "reviews",
    route: "/reviews",
    component: <Reviews />,
    noCollapse: true,
  },
  {
    name: "Mama za pet",
    key: "about",
    route: "/about",
    component: <About />,
    noCollapse: true,
  },
  {
    name: "Review Survey",
    key: "review-survey",
    route: "/review-survey",
    component: <ReviewSurvey />,
    noCollapse: true,
  },
  // {
  //   name: "Kontakt",
  //   key: "contact",
  //   route: "/contact",
  //   component: <Contact />,
  //   noCollapse: true,
  // },
  {
    key: "product-details",
    route: "/product/:productId",
    component: <ProductDetails />,
  },
  {
    key: "checkout",
    route: "/checkout",
    component: <Checkout />,
  },
  {
    key: "checkout-preview",
    route: "/checkout/preview",
    component: <CheckoutPreview />,
  },
  {
    key: "thankyou",
    route: "/thankyou/:orderId",
    component: <ThankYou />,
  },
  {
    key: "cookies-policy",
    route: "/cookies-policy",
    component: <CookiesPolicy />,
  },
  {
    key: "terms-and-conditions",
    route: "/terms-and-conditions",
    component: <TermsAndConditions />,
  },
  {
    key: "privacy-policy",
    route: "/privacy-policy",
    component: <PrivacyPolicy />,
  },
];

export default userRoutes;
