import { useEffect, useState } from "react";

import moment from "moment";

import { Divider, Grid, Icon, Menu, MenuItem } from "@mui/material";

import typography from "assets/theme/base/typography";
import breakpoints from "assets/theme/base/breakpoints";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DashboardLayout from "pages/layout/dashboard-layout";
import GradientLineChart from "pages/admin/dashboard/components/charts/line-charts/gradient-line-chart";
import DefaultStatisticsCard from "pages/admin/dashboard/components/statistic-cards/default-statistics-card";

import { revenueMockData, orderMockData, productMockData } from "pages/admin/dashboard/mock";
import HorizontalBarChart from "pages/admin/dashboard/components/charts/bar-charts/horizontal-bar-chart";

const Dashboard = (props) => {
  const { values } = breakpoints;
  const { size } = typography;

  const [unprocessedOrders, setUnprocessedOrders] = useState("6 Maj - 7 Maj");
  const [revenue, setRevenue] = useState("1 Maj - 7 Maj");
  const [numberOfOrders, setNumberOfOrders] = useState("1 Maj - 7 Maj");
  const [numberOfCustomers, setSalesDropdownValue] = useState("1 Maj - 7 Maj");
  const [interval, setInterval] = useState(null);
  const [intervalValue, setIntervalValue] = useState("Poslednjih 7 dana");

  const openRevenueDropdown = ({ currentTarget }) => setInterval(currentTarget);
  const closeIntervalDropdown = ({ currentTarget }) => {
    setInterval(null);
    setIntervalValue(currentTarget.innerText || intervalValue);
  };

  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Poslednjih 7 dana</MenuItem>
      <MenuItem onClick={close}>Poslednjih 30 dana</MenuItem>
      <MenuItem onClick={close}>Poslednjih 90 dana</MenuItem>
    </Menu>
  );

  const dropdown = {
    action: openRevenueDropdown,
    menu: renderMenu(interval, closeIntervalDropdown),
    value: intervalValue,
  }

  const [dailyRevenueData, setDailyRevenueData] = useState([]);
  const [weeklyRevenueData, setWeeklyRevenueData] = useState([]);

  useEffect(() => {
    setDailyRevenueData(
      {
        labels: revenueMockData.mockDailyRevenueData.map(row => moment(new Date(row.date)).format("Do MMM")),
        datasets: [
          {
            label: "Trenutno stanje",
            color: "success",
            data: revenueMockData.mockDailyRevenueData.map(row => row.currentValue)
          },
          {
            label: "Prethodno stanje",
            color: "secondary",
            data: revenueMockData.mockDailyRevenueData.map(row => row.previousValue)
          }
        ]
      }
    );
    setWeeklyRevenueData(
      {
        labels: revenueMockData.mockWeeklyRevenueData.map(row => moment(new Date(row.date)).format("Do MMM")),
        datasets: [
          {
            label: "Trenutno stanje",
            color: "success",
            data: revenueMockData.mockWeeklyRevenueData.map(row => row.currentValue)
          },
          {
            label: "Prethodno stanje",
            color: "secondary",
            data: revenueMockData.mockWeeklyRevenueData.map(row => row.previousValue)
          }
        ]
      }
    );
    // get(`${SERVER_URL_PREFIX}/api/admin/dashboard/mock`)
    //   .then(response => response.json())
    //   .then(data => setRevenueData(
    //     {
    //       labels: data.data.map(row => moment(new Date(row.date)).format("Do MMM")),
    //       datasets: [
    //         {
    //           label: "Trenutno stanje",
    //           color: "success",
    //           data: data.data.map(row => row.currentValue)
    //         },
    //         {
    //           label: "Prethodno stanje",
    //           color: "secondary",
    //           data: data.data.map(row => row.previousValue)
    //         }
    //       ]
    //     }
    //   ));
  }, []);

  const [numberOfOrdersDailyData, setNumberOfOrdersDailyData] = useState([]);

  useEffect(() => {
    setNumberOfOrdersDailyData(
      {
        labels: orderMockData.mockDailyOrdersData.map(row => moment(new Date(row.date)).format("Do MMM")),
        datasets: [
          {
            label: "Trenutno stanje",
            color: "success",
            data: orderMockData.mockDailyOrdersData.map(row => row.currentValue)
          },
          {
            label: "Prethodno stanje",
            color: "secondary",
            data: orderMockData.mockDailyOrdersData.map(row => row.previousValue)
          }
        ]
      }
    );
    // get(`${SERVER_URL_PREFIX}/api/admin/dashboard/mock`)
    //   .then(response => response.json())
    //   .then(data => setNumberOfOrdersData(
    //     {
    //       labels: data.data.map(row => moment(new Date(row.date)).format("Do MMM")),
    //       datasets: [
    //         {
    //           label: "Trenutno stanje",
    //           color: "success",
    //           data: data.data.map(row => row.currentValue)
    //         },
    //         {
    //           label: "Prethodno stanje",
    //           color: "secondary",
    //           data: data.data.map(row => row.previousValue)
    //         }
    //       ]
    //     }
    //   ));
  }, []);


  const [numberOfSoldProductsDailyData, setNumberOfSoldProductsDailyData] = useState([]);

  useEffect(() => {
    setNumberOfSoldProductsDailyData(
      {
        labels: orderMockData.mockDailySoldProductsData.map(row => moment(new Date(row.date)).format("Do MMM")),
        datasets: [
          {
            label: "Trenutno stanje",
            color: "success",
            data: orderMockData.mockDailySoldProductsData.map(row => row.currentValue)
          },
          {
            label: "Prethodno stanje",
            color: "secondary",
            data: orderMockData.mockDailySoldProductsData.map(row => row.previousValue)
          }
        ]
      }
    );
    // get(`${SERVER_URL_PREFIX}/api/admin/dashboard/mock`)
    //   .then(response => response.json())
    //   .then(data => setNumberOfOrdersData(
    //     {
    //       labels: data.data.map(row => moment(new Date(row.date)).format("Do MMM")),
    //       datasets: [
    //         {
    //           label: "Trenutno stanje",
    //           color: "success",
    //           data: data.data.map(row => row.currentValue)
    //         },
    //         {
    //           label: "Prethodno stanje",
    //           color: "secondary",
    //           data: data.data.map(row => row.previousValue)
    //         }
    //       ]
    //     }
    //   ));
  }, []);

  // const horizontalBarChartData = {
  //   labels: ["16-20", "21-25", "26-30", "31-36", "36-42", "42+"],
  //   datasets: [
  //     {
  //       label: "Sales by age",
  //       color: "dark",
  //       data: [15, 20, 12, 60, 20, 15],
  //     },
  //   ],
  // };

  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SoftBox p={1}>
            <SoftTypography
              variant={window.innerWidth < values.sm ? "h3" : "h2"}
              textTransform="capitalize"
              fontWeight="bold"
            >
              <Grid container spacing={3}>
                {/* <Grid item xs={12} md={6} lg={6} xl={6} xxl={3}>
                  <DefaultStatisticsCard
                    title="Neobrađene narudžbe"
                    count="22"
                    percentage={{
                      color: "success",
                      value: "+55%",
                      label: "u odnosu na prethonih 7 dana",
                    }}
                    dropdown={{
                      value: unprocessedOrders,
                    }}
                  />
                </Grid> */}
                <Grid item xs={12} md={6} lg={6} xl={6} xxl={3}>
                  <DefaultStatisticsCard
                    title="Neobrađene narudžbe"
                    count="22"
                    percentage={{
                      color: "success",
                      value: "+55%",
                      label: "u odnosu na 7 dana ranije",
                    }}
                    rangeLabel="Poslednjih 7 dana"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6} xxl={3}>
                  <DefaultStatisticsCard
                    title="Zarada - bruto"
                    count="$230,220"
                    percentage={{
                      color: "success",
                      value: "+55%",
                      label: "u odnosu na 30 dana ranije",
                    }}
                    rangeLabel="Poslednjih 30 dana"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6} xxl={3}>
                  <DefaultStatisticsCard
                    title="Broj narudžbi"
                    count="120"
                    percentage={{
                      color: "success",
                      value: "+55%",
                      label: "u odnosu na 30 dana ranije",
                    }}
                    rangeLabel="Poslednjih 30 dana"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6} xxl={3}>
                  <DefaultStatisticsCard
                    title="Broj kupljenih proizvoda"
                    count="124"
                    percentage={{
                      color: "success",
                      value: "+55%",
                      label: "u odnosu na 30 dana ranije",
                    }}
                    rangeLabel="Poslednjih 30 dana"
                  />
                </Grid>
              </Grid>
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Divider sx={{ width: "100%", mb: 0 }} />

        {/* <Grid item xs={12} display="flex" justifyContent="left">
          <SoftBox width="100%" textAlign="right" lineHeight={1} display="flex" justifyContent="start" alignItems="center" p={1}>
            <SoftTypography
              variant="caption"
              color="secondary"
            >
              Interval:
            </SoftTypography>&nbsp;&nbsp;&nbsp;
            <SoftTypography
              variant="caption"
              // color="secondary"
              sx={{ cursor: "pointer" }}
              onClick={dropdown.action}
            >
              {dropdown.value}
            </SoftTypography>
            {dropdown.menu}
          </SoftBox> 
        </Grid>
        */}
        <Grid item xs={12} lg={6}>
          <GradientLineChart
            title="Zarada - bruto"
            description={
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Na dnevnom nivou - u odnosu na 30 dana ranije
              </SoftTypography>
            }
            // description={
            //   <SoftBox display="flex" alignItems="center">
            //     <SoftBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
            //       <Icon sx={{ fontWeight: "bold" }}>arrow_upward</Icon>
            //     </SoftBox>
            //     <SoftTypography variant="button" color="text" fontWeight="medium">
            //       4% more{" "}
            //       <SoftTypography variant="button" color="text" fontWeight="regular">
            //         in 2021
            //       </SoftTypography>
            //     </SoftTypography>
            //   </SoftBox>
            // }
            rangeLabel="Poslednjih 30 dana"
            chart={dailyRevenueData}
            height={"300px"}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GradientLineChart
            title="Zarada - bruto"
            description={
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Na sedmičnom nivou - u odnosu na godinu dana ranije
              </SoftTypography>
            }
            rangeLabel="Poslednjih godinu dana"
            chart={weeklyRevenueData}
            height={"300px"}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GradientLineChart
            title="Broj narudžbi"
            description={
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Na dnevnom nivou - u odnosu na 30 dana ranije
              </SoftTypography>
            }
            rangeLabel="Poslednjih 30 dana"
            chart={numberOfOrdersDailyData}
            height={"300px"}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <GradientLineChart
            title="Broj prodatih proizvoda"
            description={
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Na dnevnom nivou - u odnosu na 30 dana ranije
              </SoftTypography>
            }
            rangeLabel="Poslednjih 30 dana"
            chart={numberOfSoldProductsDailyData}
            height={"300px"}
          />
        </Grid>
        <Grid item xs={12}>
          <HorizontalBarChart
            title="Broj prodatih proizvoda"
            description="Najprodavaniji proizvodi u poslednjih 30 dana"
            chart={productMockData.productSalesMockData}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  )
}

export default Dashboard;