/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { IconButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SoftBox from 'components/SoftBox';
import 'photoswipe/style.css';

const SimpleGallery = (props) => {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#' + props.galleryId,
      children: 'a',
      pswpModule: () => import('photoswipe'),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  function Arrow({
    children,
    disabled,
    onClick
  }) {
    return (
      <IconButton
        disabled={disabled}
        onClick={onClick}
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          right: "1%",
          opacity: disabled ? "0" : "1",
          userSelect: "none"
        }}
      >
        {children}
      </IconButton>
    );
  }

  function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
      React.useContext(VisibilityContext);

    return (
      <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        <KeyboardArrowLeftIcon />
      </Arrow>
    );
  }

  function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
      <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
        <KeyboardArrowRightIcon />
      </Arrow>
    );
  }

  return (
    <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
      {props.images.map((image, i) => (
        <SoftBox className="pswp-gallery" id={props.galleryId} display="flex" key={i} >
          <SoftBox key={i} sx={{
            mr: "0.5rem",
            minHeight: "8rem",
            maxHeight: "8rem",
          }}>
            <a
              href={image.largeURL}
              data-pswp-width={image.width}
              data-pswp-height={image.height}
              key={props.galleryId + '-' + i}
              target="_blank"
              rel="noreferrer"
            >
              <SoftBox
                component="img"
                loading="lazy"
                src={image.thumbnailURL}
                alt={`Galerija ${i}`}
                sx={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </a>
          </SoftBox>
        </SoftBox>
      ))}
    </ScrollMenu>

  );
}

export default SimpleGallery;