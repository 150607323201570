import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton, Grid, Icon } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SuggestedProduct from 'pages/user/product-pages/components/suggested-product';
import { RESOURCES_SERVER_URL_PREFIX, SERVER_URL_PREFIX } from "api";

const ScrollableProductList = ({ products, selectedCurrency, handleAddToCart }) => {
  const scrollRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
      }
    };

    handleScroll(); // Initial check
    const currentScrollRef = scrollRef.current;
    currentScrollRef.addEventListener('scroll', handleScroll);
    return () => {
      if (currentScrollRef) {
        currentScrollRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -270, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 270, behavior: 'smooth' });
    }
  };

  return (
    <SoftBox display="flex" justifyContent="center" alignItems="center" pt="2rem" gap="1rem" px="2rem">
      {showLeftArrow && (
        <IconButton onClick={scrollLeft}>
          <Icon>chevron_left</Icon>
        </IconButton>
      )}
      <Box
        ref={scrollRef}
        sx={{
          display: 'flex',
          overflowX: 'auto',
          maxWidth: '100%',
          px: '1rem',
          gap: '1rem',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {products.map((product) => (
          <Box key={product.id} sx={{ minWidth: '270px', flex: '0 0 auto' }}>
            <SuggestedProduct
              id={product.id.toString()}
              title={product.name}
              type={product.type}
              compositeProductItems={product.compositeProductItems}
              price={selectedCurrency.value === 'bam' ? product.unitPriceBam.toString() : product.unitPriceEur.toString()}
              currency={selectedCurrency}
              quantity={product.quantity}
              image={`${RESOURCES_SERVER_URL_PREFIX}${product.imageLocation}`}
              onAddToCart={(product) => handleAddToCart(product)}
              specialOffers={product.specialOffers}
              lazyLoading={true}
            />
          </Box>
        ))}
      </Box>
      {showRightArrow && (
        <IconButton onClick={scrollRight}>
          <Icon>chevron_right</Icon>
        </IconButton>
      )}
    </SoftBox>
  );
};

export default ScrollableProductList;