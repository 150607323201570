import { Divider, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import PageLayout from "pages/layout/page-layout";
import ProductDescription from "pages/user/product-pages/krema-protiv-bora/product-description";
import Reviews from "pages/user/reviews";
import { useDocumentTitle } from "hooks/use-document-title";
import { SERVER_URL_PREFIX } from "api";
import ProductDetails from "pages/user/components/product-details";
import { Tabs, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { get } from "api";
import { useSoftUIController, setOpenConfigurator } from "context";
import ScrollableProductList from "pages/user/product-pages/components/scrollable-product-list";
import SoftTypography from "components/SoftTypography";
import { addCartItem, updateProduct } from "context";
import { RESOURCES_SERVER_URL_PREFIX } from "api";

const KremaSerumSet = () => {
  useDocumentTitle("Mama za pet - Krema-Serum Set");
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const [controller, dispatch] = useSoftUIController();
  const { selectedCurrency, cartItems } = controller;
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);

  useEffect(() => {
    setIsLoadingProducts(true);
    get(`${SERVER_URL_PREFIX}/api/web/products`)
      .then(response => response.json())
      .then(data => {
        setProducts(data)
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  }, []);

  const getProductsAddToCardData = (productName, productQuantity) => {
    const product = products.find(p => p.name === productName);

    if (product) {
      const { id, name, imageLocation, quantity, unitPriceBam, unitPriceEur } = product;
      const price = selectedCurrency?.value === "bam" ? unitPriceBam : unitPriceEur;

      return {
        id: String(id),
        name: name,
        img: `${RESOURCES_SERVER_URL_PREFIX}${imageLocation}`,
        quantity: productQuantity,
        unitPrice: price,
        unitQuantity: quantity,
      };
    }

    return undefined;
  };

  const handleAddToCart = (product) => {
    const currentProduct = cartItems.find(item => item.id === product.id);
    get(`${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${currentProduct ? currentProduct.quantity + 1 : product.quantity}&currency=${selectedCurrency.value.toUpperCase()}`)
      .then(response => response.json())
      .then(data => {
        if (!currentProduct) {
          addCartItem(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice });
        } else {
          updateProduct(dispatch, { ...product, realPrice: data.realPrice, calculatedPrice: data.calculatedPrice, quantity: currentProduct.quantity + product.quantity });
        }
      })
  }

  const handleAdd = (product) => { setTimeout(handleConfiguratorOpen, 300); handleAddToCart(getProductsAddToCardData(product.name, product.quantity)); };

  return (
    <PageLayout sx={{ overflow: "hidden" }}>
      <Divider sx={{ mb: "0" }} />

      <ProductDetails
        product={products.find(p => p.name === "Krema-Serum Set")}
        onAddToCart={handleAdd}
        productImages={[
          `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/all-in-one-set-new-square.webp`,
          `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/blue/krema-protiv-bora-square.webp`,
          `${RESOURCES_SERVER_URL_PREFIX}/static/images/products/serum/serum-postolje.webp`,
          `${RESOURCES_SERVER_URL_PREFIX}/static/images/voda-zeolitna-maska-za-lice.webp`,
          `${RESOURCES_SERVER_URL_PREFIX}/static/images/voda-krema-protiv-bora.webp`,
        ]}
        descriptionContentArray={[
          "Sve što treba suvoj, kombinovanoj i masnoj koži je ovaj serum za lice.",
          "Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot(takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti.",
          "U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice."
        ]}
      />

      <Grid container
        width={{ xs: "100%", lg: "100%", xl: "100%" }}
        sx={{
          display: "flex",
          alignItems: "flex-start",
          padding: "0rem",
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          sx={{
            width: "100%",
            mb: "1rem",
            backgroundColor: "white !important",
            borderBottom: "1px solid #e0e0e0",
            borderTop: "1px solid #e0e0e0",
            borderRadius: "0",
            padding: "0",
            '& .MuiTab-root': {
              minHeight: "64px !important",
              color: "black",
              maxWidth: "220px !important",
              '&:hover': {
                color: "#6ac4bb !important",
              },
            },
            '& .Mui-selected': {
              color: "#6ac4bb !important",
              borderBottom: "3px solid #7eddd3",
              borderRadius: "0",
            },
            '& .MuiTabs-indicator': {
              display: "none",
              borderBottom: "1px solid black",
            },
          }}
        >
          <Tab label="O PROIZVODU" />
          <Tab label="SASTOJCI" />
          <Tab label="NAČIN UPOTREBE" />
        </Tabs>

        {tabIndex === 0 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto"
            }}
            titleSx={{ pb: "1rem" }}
            title="Daje rezultate već nakon 7 dana!"
            textVariant="body2"
            textArray={[
              "Sve što treba suvoj, kombinovanoj i masnoj koži je ovaj serum.",
              "Tu je i australijski čajevac koji ima antibakterijsko djelovanje što znači da uspješno otklanja akne, herpes..), zatim i bergamot(takođe pomaže kod kožnih infekcija), kao i eukaliptus, ruža i još sedam ulja koja njeguju kožu i sve njene nepravilnosti. U svakom pakovanju čeka te eterično ulje smilja koje pomaže kod akni, ožiljaka, dermatitisa, ekcema i snažno regeneriše kožu. Nazivaju ga još i zlatnim uljem za lice.",
              "Naša serum za lice je bogata prirodnim sastojcima koji dubinski hrane i obnavljaju kožu. Sadrži hijaluronsku kiselinu koja zadržava vlagu i čini kožu glatkom i elastičnom. Maska je obogaćena i kolagenom koji pomaže u obnavljanju strukture kože, čineći je čvršćom i otpornijom na znakove starenja.",
              "Pored hijaluronske kiseline, serum sadrži i vitamin C koji je poznat po svojim antioksidativnim svojstvima. Vitamin C pomaže u borbi protiv slobodnih radikala i posvetljuje tamne mrlje na koži.",
              "Maska je obogaćena i kolagenom koji pomaže u obnavljanju strukture kože, čineći je čvršćom i otpornijom na znakove starenja. Naša formula sadrži i prirodne ekstrakte kamilice i lavande koji umiruju kožu i smanjuju crvenilo. Ovi sastojci su idealni za osjetljivu kožu sklonu iritacijama.",
              "Maska se lako upija i ne ostavlja masni trag, što je čini idealnom za svakodnevnu upotrebu. Može se koristiti kao podloga za šminku ili kao noćna maska. Redovnom upotrebom naše kreme protiv bora, vaša koža će postati mekša, glatkija i vidljivo mlađa. Uživajte u blistavom i zdravom izgledu kože svaki dan.",
            ]}
          />
        )}
        {tabIndex === 1 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto"
            }}
            titleSx={{ pb: "1rem" }}
            title="Sastojci"
            textVariant="body2"
            textArray={[
              "Naša maska za lice sadrži pažljivo odabrane sastojke koji pružaju optimalnu njegu vašoj koži.",
              "Hijaluronska kiselina: Zadržava vlagu u koži, čineći je glatkom i elastičnom. Pomaže u smanjenju bora i finih linija.",
              "Vitamin C: Poznat po svojim antioksidativnim svojstvima, pomaže u borbi protiv slobodnih radikala i posvetljuje tamne mrlje na koži.",
              "Kolagen: Pomaže u obnavljanju strukture kože, čineći je čvršćom i otpornijom na znakove starenja.",
              "Eterična ulja: Sadrži ulje smilja, australijskog čajevca, bergamota, eukaliptusa, ruže i još sedam ulja koja njeguju kožu i sve njene nepravilnosti.",
              "Prirodni ekstrakti: Kamilica i lavanda umiruju kožu i smanjuju crvenilo. Idealni su za osjetljivu kožu sklonu iritacijama.",
            ]}
          />
        )}
        {tabIndex === 2 && (
          <ProductDescription
            sx={{
              px: "1rem",
              pt: "1rem",
              maxWidth: "900px",
              margin: "0 auto"
            }}
            titleSx={{ pb: "1rem" }}
            title="Način upotrebe"
            textVariant="body2"
            textArray={[
              "Naša maska protiv bora je jednostavna za upotrebu i može se koristiti svakodnevno.",
              "Korak 1: Očistite lice blagim sredstvom za čišćenje i osušite ga tapkanjem peškirom.",
              "Korak 2: Nanesite malu količinu kreme na lice i vrat. Izbegavajte područje oko očiju.",
              "Korak 3: Lagano masirajte kremu kružnim pokretima dok se potpuno ne upije u kožu.",
              "Korak 4: Koristite kremu ujutru kao podlogu za šminku ili uveče kao noćnu kremu.",
              "Saveti: Za najbolje rezultate, koristite kremu redovno i kombinujte je sa zdravim načinom života i ishranom bogatom antioksidansima.",
            ]}
          />
        )}
      </Grid>

      <Divider sx={{ mb: "0", mt: "3rem" }} />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        pt="1rem"
      >
        <SoftTypography
          variant="h3"
          sx={{ color: "#000" }}
        >
          Pogledajte i ostale proizvode koji bi vam se mogli svidjeti
        </SoftTypography>
      </SoftBox>
      <Divider sx={{ mb: "0" }} />

      <ScrollableProductList
        products={products.filter(p => p.name !== "Krema-Serum Set")}
        selectedCurrency={selectedCurrency}
        handleAddToCart={(product) => handleAdd(product)}
      />

      <SoftBox display="flex" justifyContent="center" mt="4rem" />

      <Reviews productId="1" />

    </PageLayout >
  )
}

export default KremaSerumSet;