import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PageLayout from "pages/layout/page-layout";
import ProductReviewItem from "pages/user/review-survey/product-review-item";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import SoftButton from "components/SoftButton";
import { useSearchParams } from "react-router-dom";
import { get, post, SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";
import { Divider, Grid, Icon, Link } from "@mui/material";
import { useDocumentTitle } from "hooks/use-document-title";
import { ReactComponent as Reviews } from 'assets/icons/reviews.svg';

const ReviewSurvey = () => {
  useDocumentTitle("Mama za pet - Forma Za Recenzije");

  const [reviewRequestData, setReviewRequestData] = useState({});
  const [errorOccured, setErrorOccured] = useState(false);
  const [searchParams] = useSearchParams();
  const [submitCompleted, setSubmitCompleted] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [images, setImages] = useState({});

  const reviewRequestPublicId = searchParams.get('reviewRequestPublicId');
  const expiration = searchParams.get('expiration');
  const isLinkExpired = expiration
    ? new Date(Number(expiration)) < new Date()
    : false;

  useEffect(() => {
    if (isLinkExpired || !reviewRequestPublicId) return;

    get(`${SERVER_URL_PREFIX}/api/web/review/${reviewRequestPublicId}`)
      .then(response => response.json())
      .then(response => {
        setReviewRequestData(response);
      })
      .catch(error => {
        console.error(error);
      });
  }, [isLinkExpired, reviewRequestPublicId]);

  const productReviewsInitialValues = useMemo(() => reviewRequestData && reviewRequestData.products ? reviewRequestData.products.reduce((acc, product) => {
    acc[`rating_${product.id}`] = 0;
    acc[`review_${product.id}`] = '';
    return acc;
  }, {}) : {}, [reviewRequestData]);

  function validateReviewsAndRatings(data) {
    if (Object.keys(data).length !== reviewRequestData.products?.length * 2) return false;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key.startsWith('rating_')) {
          const ratingValue = data[key];
          if (typeof ratingValue !== 'number' || ratingValue < 1 || ratingValue > 5) {
            return false; // Rating should be a number between 1 and 5
          }
        } else if (key.startsWith('review_')) {
          const reviewValue = data[key];
          if (typeof reviewValue !== 'string' || reviewValue.trim().length === 0) {
            return false; // Review should be a non-empty string
          }
        } else {
          // Invalid key prefix
          return false;
        }
      }
    }
    return true; // All keys passed validation
  }

  const generateProductReviews = (values) => {
    const productReviewsMap = new Map();

    Object.keys(values).map(key => {
      const [prefix, productId] = key.split('_');
      productReviewsMap.set(productId, { ...productReviewsMap.get(productId) || {}, [prefix]: values[key] });
    })

    const productReviews = [];
    productReviewsMap.forEach((productReview, productId) => {
      productReview.productId = productId;
      productReviews.push(productReview);
    });
    return productReviews;
  };

  const onSubmit = (values, { setSubmitting }) => {
    setErrorOccured(false);
    
    const isValid = validateReviewsAndRatings(values);
    if (!isValid) {
      setErrorOccured(true);
      setSubmitting(false);
      return;
    }
  
    const formData = new FormData();
    formData.append('publicOrderId', reviewRequestData.publicOrderId);
  
    const productReviews = generateProductReviews(values);
  
    productReviews.forEach((review, index) => {
      formData.append(`productReview_productId_${review.productId}`, review.productId);
      formData.append(`productReview_rating_${review.productId}`, review.rating);
      formData.append(`productReview_review_${review.productId}`, review.review);
  
      if (images[review.productId]) {
        images[review.productId].forEach((file, fileIndex) => {
          formData.append(`files`, file, `image_${reviewRequestData.publicOrderId}_${review.productId}_${fileIndex}.${file.name.split('.').pop()}`);
        });
      }
    });
  
    post(`${SERVER_URL_PREFIX}/api/web/review/submit`, formData, {
      headers: {
        // 'Content-Type': 'multipart/form-data',
      },
      body: formData
    })
      .then(response => response.status === 200 ? setSubmitCompleted(true) : setSubmitError(true))
      .catch(error => {
        console.error(error);
        setSubmitError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  

  if (!reviewRequestPublicId || !expiration || isLinkExpired) {
    return (
      <SoftBox display="flex" justifyContent="center" alignItems="center">
        <SoftTypography variant="h4" sx={{ mt: "2rem" }} color="error">Link za recenziju je istekao</SoftTypography>
      </SoftBox>
    );
  }

  if (submitCompleted) {
    return (
      <PageLayout>
        <Grid container>
          <Grid item xs={12}>
            <Divider sx={{ mb: "0" }} />
            <SoftBox display="flex" sx={({ palette }) => ({
              flexDirection: 'column',
              backgroundColor: palette.success.main,
              p: { xs: "1rem" },
              alignItems: 'center',
              justifyContent: "center"
            })}>
              <Reviews fill='#ffffff' width={60} height={60} />
              <SoftTypography sx={({ typography }) => ({
                fontSize: { xs: typography.size.lg, lg: typography.size.xl },
                textAlign: "center",
                color: "#fff",
              })}>
                Hvala što ste ostavili recenziju
              </SoftTypography>
            </SoftBox>
          </Grid>
          {
            reviewRequestData.rewardCouponEligible &&
            <Grid item xs={12} sx={{ textAlign: "center", mb: "3rem" }}>
              <SoftBox display="flex" flexDirection="column" sx={({ palette }) => ({
                backgroundColor: palette.grey[100],
                p: "2rem",
                justifyContent: "center"
              })}>
                <SoftBox display="flex" justifyContent="center" sx={{ textAlign: "center" }}>
                  <SoftTypography variant="body2" sx={{ fontStyle: "italic" }}>
                    Kupon će biti proslijeđen na Vašu email adresu kada recenzija bude odobrena
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </Grid>
          }
          <Grid item xs={12} mt="5rem" mb="1rem">
            <SoftBox display="flex" justifyContent="center" sx={{ margin: "0 auto" }}>
              <SoftButton
                component={Link}
                href="/store"
                color="info"
                variant="gradient"
                sx={{ maxWidth: "25rem" }}
                fullWidth
              >
                <Icon>shopping_bag</Icon>&nbsp; Posjeti prodavnicu
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      </PageLayout>
    );
  }

  if (submitError) {
    return (
      <>
        <SoftBox display="flex" justifyContent="center" alignItems="center">
          <SoftTypography variant="h4" sx={{ mt: "2rem" }} color="error">Desila se greška</SoftTypography>
        </SoftBox>
        <SoftBox display="flex" justifyContent="center" alignItems="center">
          <SoftTypography variant="body2" sx={{ mt: "2rem" }}>Kontaktirajte podrsku mama za pet na email <b>info@mamazapet.com</b></SoftTypography>
        </SoftBox>
      </>
    );
  }

  return (
    <PageLayout sx={{ overflow: "hidden", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Divider sx={{ width: "100%" }} />
      <SoftBox display="flex" justifyContent="center" id="review-heading-container">
        <SoftTypography variant="h3">Forma za recenzije</SoftTypography>
      </SoftBox>
      <Divider sx={{ width: "100%", mb: 0 }} />

      {
        reviewRequestData.rewardCouponEligible &&
        <SoftBox display="flex" sx={({ palette }) => ({
          backgroundColor: palette.grey[100],
          p: "1rem",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
        })}>
          <SoftTypography sx={({ typography }) => ({
            fontSize: typography.size.sm,
            textAlign: { xs: "left", md: "center" }
          })}>
            Popunjavanjem recenzije dobićete <b>kupon za popust</b> koji možete iskoristiti u narednih 90 dana.
          </SoftTypography>
          <SoftBox display={{ xs: "flex", md: "none" }} justifyContent="center" mt="1rem"></SoftBox>
          <SoftTypography sx={({ typography }) => ({
            fontSize: typography.size.sm,
            textAlign: { xs: "left", md: "center" }
          })}>
            Kupon će biti proslijeđen na Vašu email adresu <b>{reviewRequestData.email}</b> kada recenzija bude odobrena. &nbsp;
          </SoftTypography>
        </SoftBox>
      }

      <SoftBox display="flex" justifyContent="center" width="100%" px="1rem" mt="3rem">
        <Formik
          initialValues={productReviewsInitialValues}
          onSubmit={onSubmit}
        >
          {({ values, errors, touched, isSubmitting, setValues }) => (
            <Form id={"review-form"} autoComplete="off" style={{
              display: "flex", flexDirection: 'column',
              width: '100%',
              maxWidth: '900px',
              gap: '3rem'
            }}>
              {
                reviewRequestData.products?.map((product, index) => (
                  <ProductReviewItem
                    key={product.id}
                    productId={product.id}
                    name={product.name}
                    price={100}
                    unitQuantity={product.quantity}
                    img={`${RESOURCES_SERVER_URL_PREFIX}${product.imageLocation}`}
                    ratingFieldName={`rating_${product.id}`}
                    reviewFieldName={`review_${product.id}`}
                    values={values}
                    errors={errors}
                    touched={touched}
                    isSubmitting={isSubmitting}
                    setValues={setValues}
                    setImages={setImages}
                  />
                ))
              }
              <SoftTypography
                component="div" 
                variant="caption" 
                sx={{ fontStyle: "italic", fontSize: '0.875rem' }}
                ml={{ xs: "0", md: "12.7rem" }}
                pl={{ xs: "0", md: "1rem"}}
              >
                Napomena: Sva polja označena zvjezdicom (*) su obavezna.
              </SoftTypography>
              <SoftBox
                display="flex"
                flexDirection={{xs: "column", md: "row"}}
                alignItems={{xs: "flex-end", md: "center"}}
                justifyContent={{xs: "left", md: "right"}}
                gap="2rem"
                ml={{ xs: "0", md: "12.7rem" }}
                pl={{ xs: "0", md: "1rem"}}
              >
                {errorOccured ?
                  <SoftTypography color="error" fontSize="14px" fontWeight="medium" alignSelf="flex-start">
                    Niste popunili sve potrebne podatke. Provjerite da li ste unijeli sve ocjene i komentare.
                  </SoftTypography> :
                  null
                }
                <SoftButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="gradient"
                  color="success"
                  sx={{
                    width: 'fit-content',
                    minWidth: '10rem',
                  }}
                >
                  Pošalji recenzije
                </SoftButton>
              </SoftBox>
            </Form>
          )}
        </Formik>
      </SoftBox>

    </PageLayout >
  )
}

export default ReviewSurvey;