import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

const CoverImageWithText = ({ imageUrl, title, description, buttonText, onButtonClick }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const styleForCoverImage = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: isSmallScreen ? "100%" : "50%",
    height: isSmallScreen ? "300px" : "550px",
  };

  const styleForTextSection = {
    backgroundColor: "#eeeeee",
    width: isSmallScreen ? "100%" : "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: isSmallScreen ? "2rem 1rem" : "2rem",
    color: "#000",
  };

  return (
    <Grid container direction={isSmallScreen ? "column" : "row"}>
      <Grid item sx={styleForCoverImage}></Grid>
      <Grid item sx={styleForTextSection}>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "80%",
          }}
        >
          <SoftTypography
            variant="h4"
            gutterBottom
            textAlign="center"
            sx={{
              color: "#000",
              fontWeight: "600",
              mb: "1.5rem",
            }}
          >
            {title}
          </SoftTypography>
          <SoftTypography
            variant="body2"
            gutterBottom
            textAlign="center"
            sx={{
              color: "#000",
              fontSize: "14px",
              mb: "1.5rem",
            }}
          >
            {description}
          </SoftTypography>
          <SoftButton variant="contained" color="primary" onClick={onButtonClick}>
            {buttonText}
          </SoftButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

CoverImageWithText.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default CoverImageWithText;
