import React from 'react';
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

const Price = ({ originalPrice, currency }) => {
  return (
    <SoftTypography sx={({ typography }) => ({
      fontSize: typography.size.lg,
      fontWeight: typography.fontWeightMedium,
      color: '#000',
    })}>
      {originalPrice} {currency}
    </SoftTypography>
  );
};

Price.propTypes = {
  originalPrice: PropTypes.number,
  currency: PropTypes.string
};

Price.defaultProps = {
  originalPrice: 0,
  currency: "BAM"
};

export default Price;