import { IconButton, Icon } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

const PrimaryQuantityCounter = ({ value, onIncrease, onDecrease, minValue, maxValue, readOnly, sx }) => {
  const removeButtonDisabled = value <= minValue;
  const addButtonDisabled = value >= maxValue;

  return (
    <SoftBox
      display="flex"
      alignItems="center"
      border="1px solid"
      borderColor="rgb(238, 238, 238)"
      borderRadius="md"
      overflow="hidden"
      gap="1rem"
      sx={{
        ...sx,
        width: "8rem",
        height: "2.5rem",
      }}
    >
      {/* Decrease button */}
      <IconButton
        onClick={onDecrease}
        disabled={removeButtonDisabled || readOnly}
        sx={{
          flex: 1,
          height: "100%",
          backgroundColor: "white",
          borderRadius: 0,
          color: "white",
          "&:disabled": {
            backgroundColor: "rgb(238, 238, 238)",
            opacity: 0.8,
          },
          "&:hover": {
            backgroundColor: "rgb(230, 230, 230)",
          },
        }}
      >
        <Icon>remove</Icon>
      </IconButton>

      {/* Counter display */}
      <SoftBox
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "white",
        }}
      >
        <SoftTypography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
          {value}
        </SoftTypography>
      </SoftBox>

      {/* Increase button */}
      <IconButton
        onClick={onIncrease}
        disabled={addButtonDisabled || readOnly}
        sx={{
          flex: 1,
          height: "100%",
          backgroundColor: "white",
          borderRadius: 0,
          color: "white",
          "&:disabled": {
            backgroundColor: "rgb(238, 238, 238)",
            opacity: 0.8,
          },
          "&:hover": {
            backgroundColor: "rgb(230, 230, 230)",
          },
        }}
      >
        <Icon>add</Icon>
      </IconButton>
    </SoftBox>
  );
};

PrimaryQuantityCounter.defaultProps = {
  value: 1,
  onIncrease: () => {},
  onDecrease: () => {},
  minValue: 1,
  maxValue: 9,
  readOnly: false,
};

PrimaryQuantityCounter.propTypes = {
  value: PropTypes.number,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  readOnly: PropTypes.bool,
  sx: PropTypes.object,
};

export default PrimaryQuantityCounter;
