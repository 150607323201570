import PropTypes from "prop-types";

import { Divider } from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function OrderSummary({
  productsPrice,
  shippingPrice,
  currency,
  basePrice,
  specialOffersPrice,
  subtotal,
  total
}) {
  return (
    <>
      <SoftBox mb={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          Sumarna cijena
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Bazna cijena:
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular">
          {`${basePrice} ${currency}`}
        </SoftTypography>
      </SoftBox>
      {basePrice - specialOffersPrice > 0 && (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            Popust na količinu:
          </SoftTypography>
          <SoftTypography variant="button" fontWeight="regular">
            - {`${basePrice - specialOffersPrice} ${currency}`}
          </SoftTypography>
        </SoftBox>
      )}
      {subtotal - specialOffersPrice < 0 && (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            Kupon:
          </SoftTypography>
          <SoftTypography variant="button" fontWeight="regular">
            - {`${specialOffersPrice - subtotal} ${currency}`}
          </SoftTypography>
        </SoftBox>
      )}
      <Divider />
      <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
        <SoftTypography variant="body2" fontWeight="medium" sx={{ color: "#000" }}>
          Iznos narudžbe:
        </SoftTypography>
        <SoftBox ml={1}>
          <SoftTypography variant="body2" fontWeight="medium">
            {`${subtotal} ${currency}`}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Dostava:
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="regular">
          {`${shippingPrice} ${currency}`}
        </SoftTypography>
      </SoftBox>
      <Divider />
      <SoftBox display="flex" justifyContent="space-between" mt={3}>
        <SoftTypography variant="body2" fontWeight="bold" sx={{ color: "#000" }}>
          Ukupno:
        </SoftTypography>
        <SoftBox ml={1}>
          <SoftTypography variant="body2" fontWeight="bold" sx={{ color: "#000" }}>
            {`${total} ${currency}`}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
}

OrderSummary.defaultProps = {
  productsPrice: 0,
  shippingPrice: 0,
  currency: "",
  basePrice: 0,
  specialOffersPrice: 0,
  subtotal: 0,
  total: 0
};

OrderSummary.propTypes = {
  productsPrice: PropTypes.number,
  shippingPrice: PropTypes.number,
  currency: PropTypes.string,
  basePrice: PropTypes.number,
  specialOffersPrice: PropTypes.number,
  subtotal: PropTypes.number,
  total: PropTypes.number
};

export default OrderSummary;
