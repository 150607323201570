import SoftBox from "components/SoftBox";
import { Link } from "react-router-dom";
import SoftTypography from "components/SoftTypography";
import { Rating } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import PropTypes from "prop-types";
import { RESOURCES_SERVER_URL_PREFIX } from "api";

const ReviewCard = ({ name, rating, text, date, images, product, onImageClick }) => {
  const generateProductNamePath = (name) => {
    return name.toLowerCase().replace(/ /g, "-");
  }

  const formatDate = (date) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    
    const dateTimeFormat = new Intl.DateTimeFormat('sr-Latn-RS', options);
    return dateTimeFormat.format(date)
  }

  return (
    <SoftBox display="flex" flexDirection="column" gap="10px" key={name + product.id + date} >
      <SoftBox display="flex" justifyContent="space-between">
        <SoftBox display="flex" alignItems="center" gap="10px">
          <SoftBox sx={{ width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "lightgray", display: "none", justifyContent: "center", alignItems: "center" }}>
            <SoftTypography variant="h6">{name[0].toUpperCase() + name[name.indexOf(" ") + 1].toUpperCase()}</SoftTypography>
          </SoftBox>
          <SoftBox display="flex" flexDirection="column" gap="2px" >
            <SoftTypography variant="h6">{name}</SoftTypography>
            <SoftTypography variant="caption">{formatDate(new Date(date))}</SoftTypography>
          </SoftBox>
        </SoftBox>
        <Rating
          name="text-feedback"
          value={rating}
          readOnly
          precision={1}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
      </SoftBox>
      <SoftBox display="flex">
        <Link to={`/aboutproducts/${generateProductNamePath(product.name)}`} style={{ textDecoration: "none" }}>
          <SoftBox sx={{ display: "flex", gap: "10px" }}>
            <img src={`${RESOURCES_SERVER_URL_PREFIX}${product.imageLocation}`} alt="brand" style={{ width: "30px", height: "30px" }} />
            <SoftTypography variant="p" sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "30px", textDecoration: 'underline' }}>{product.name}</SoftTypography>
          </SoftBox>
        </Link>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" marginTop="10px">
        <SoftBox display="flex" flexDirection="column" gap="10px">
          <SoftTypography variant="body2">{text}</SoftTypography>
        </SoftBox>
      </SoftBox>
      {images && images.length > 0 &&
        <SoftBox display="flex">
          <SoftBox sx={{ display: "flex", gap: "5px" }}>
            {images.map((image, index) => (
              <img src={`${RESOURCES_SERVER_URL_PREFIX}/${image}`} alt="brand" style={{ width: "30px", height: "30px" }} key={index} onClick={() => onImageClick(`${RESOURCES_SERVER_URL_PREFIX}/${image}`)} />
            ))}
          </SoftBox>
        </SoftBox>
      }
    </SoftBox>
  )
}

ReviewCard.defaultProps = {
  name: "",
  rating: 5,
  text: "",
  date: "",
  images: [],
  product: {},
  onImageClick: () => { }
};

ReviewCard.propTypes = {
  name: PropTypes.string,
  rating: PropTypes.number,
  text: PropTypes.string,
  date: PropTypes.string,
  images: PropTypes.array,
  product: PropTypes.object,
  onImageClick: PropTypes.func,
};

export default ReviewCard;