import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Formik } from "formik";

import { useSoftUIController, setCheckoutValues, selectCurrency } from "context";

import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import FormField from "pages/user/checkout/checkout-form/form-field";
import SelectBox from "pages/user/checkout/checkout-form/select-box";
import selectData from "pages/user/checkout/checkout-form/data/selectData";
import formGlobal from "pages/user/checkout/checkout-form/schemas/formGlobal";
import validationsGlobal from "pages/user/checkout/checkout-form/schemas/validationsGlobal";
import PropTypes from "prop-types";

function CheckoutForm({ submittedEvent, setFormErrors }) {
  const [controller, dispatch] = useSoftUIController();
  const navigate = useNavigate();
  const { formField: formFieldGlobal } = formGlobal;
  const {
    fullName,
    phoneNumber,
    streetAndNumber,
    city,
    postalCode,
    country,
    email,
    notes
  } = formFieldGlobal;
  const [countrySelect, _] = useState(selectData.country);
  const { cartItems, checkoutValues, countries } = controller;
  const submitButtonRef = useRef(null);

  useEffect(() => {
    if (!checkoutValues.country) {
      setCheckoutValues(dispatch, { ...checkoutValues, country: countrySelect.find(c => c.value === "bih") });
    }
  }, []);

  const handleViewOrder = (values) => {
    setCheckoutValues(dispatch, values);
    navigate("/checkout/preview")
  }

  const changeCheckoutValues = (values, handleBlur) => (e) => {
    setCheckoutValues(dispatch, values);
    handleBlur(e);
  }

  const handleCountryChange = (values, setFieldValue) => (countryKey, countryObject) => {
    setFieldValue(countryKey, countryObject);
    // setCheckoutValues(dispatch, values);
    const country = countries.find(c => c.shortName.toLowerCase() === countryObject.value.toLowerCase());
    selectCurrency(dispatch, { value: country.currency.toLowerCase(), label: country.currency })
  }

  useEffect(() => {
    if (submittedEvent) {
      const clickEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
        detail: submittedEvent.detail, // Pass the original submittedEvent's detail if needed
        screenX: submittedEvent.screenX,
        screenY: submittedEvent.screenY,
        clientX: submittedEvent.clientX,
        clientY: submittedEvent.clientY,
        ctrlKey: submittedEvent.ctrlKey,
        altKey: submittedEvent.altKey,
        shiftKey: submittedEvent.shiftKey,
        metaKey: submittedEvent.metaKey,
        button: submittedEvent.button,
        buttons: submittedEvent.buttons,
        relatedTarget: submittedEvent.relatedTarget
      });
      submitButtonRef.current.dispatchEvent(clickEvent);
    }
  }, [submittedEvent]);

  return (
    <SoftBox>
      <SoftBox component="div" px="1rem" maxWidth={{ xs: "auto", lg: "600px" }}>
        <SoftBox display="flex" justifyContent="start" mb="1rem">
          <SoftTypography variant="h6" component="span">Podaci o kupcu</SoftTypography>
        </SoftBox>
        <Formik
          onSubmit={handleViewOrder}
          initialValues={{ ...checkoutValues, country: countrySelect.find(c => c.value === checkoutValues.country) }}
          validationSchema={validationsGlobal[0]}
          enableReinitialize={true}
        >
          {({ values, errors, touched, isSubmitting, handleSubmit, handleChange, handleBlur, setFieldValue }) => (
            <Grid container>
              <Grid item xs={12}>
                <FormField
                  type={fullName.type}
                  label={fullName.label}
                  name={fullName.name}
                  placeholder={fullName.placeholder}
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={changeCheckoutValues(values, handleBlur)}
                  error={errors.fullName && touched.fullName}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={phoneNumber.type}
                  label={phoneNumber.label}
                  name={phoneNumber.name}
                  placeholder={phoneNumber.placeholder}
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={changeCheckoutValues(values, handleBlur)}
                  error={errors.phoneNumber && touched.phoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={streetAndNumber.type}
                  label={streetAndNumber.label}
                  name={streetAndNumber.name}
                  placeholder={streetAndNumber.placeholder}
                  value={values.streetAndNumber}
                  onChange={handleChange}
                  onBlur={changeCheckoutValues(values, handleBlur)}
                  error={errors.streetAndNumber && touched.streetAndNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={city.type}
                  label={city.label}
                  name={city.name}
                  placeholder={city.placeholder}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={changeCheckoutValues(values, handleBlur)}
                  error={errors.city && touched.city}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={postalCode.type}
                  label={postalCode.label}
                  name={postalCode.name}
                  placeholder={postalCode.placeholder}
                  value={values.postalCode}
                  onChange={handleChange}
                  onBlur={changeCheckoutValues(values, handleBlur)}
                  error={errors.postalCode && touched.postalCode}
                />
              </Grid>
              <Grid item xs={12} mb="1.2rem">
                <SelectBox
                  caption={country.label}
                  field={country}
                  value={values.country}
                  options={countrySelect}
                  setFieldValue={(countryKey, countryObject) => handleCountryChange(values, setFieldValue)(countryKey, countryObject)}
                  handleBlur={changeCheckoutValues(values, handleBlur)}
                  errorMessage={country.errorMsg}
                  validationFailed={Object.keys(touched).some(k => k.includes("react-select-")) && !values.country}
                  touched={touched}
                  isDisabled={true}
                  components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                />
                <SoftTypography component="div" variant="caption" sx={{ fontStyle: "italic" }}>
                  Narudžbe se trenutno primaju samo iz Bosne i Hercegovine.
                </SoftTypography>
              </Grid>
              <Grid item xs={12}>
                <FormField
                  type={email.type}
                  label={email.label}
                  name={email.name}
                  placeholder={email.placeholder}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={changeCheckoutValues(values, handleBlur)}
                  error={errors.email && touched.email}
                />
              </Grid>
              <Grid item xs={12}>
                <FormField
                  multiline
                  rows={5}
                  label={notes.label}
                  name={notes.name}
                  placeholder={notes.placeholder}
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={changeCheckoutValues(values, handleBlur)}
                />
              </Grid>
              <Grid container width="100%" justifyContent="flex-end" mb={2} mt={4} display="none">
                <Grid item xs={12}>
                  <SoftButton
                    ref={submitButtonRef}
                    variant="gradient"
                    color="success"
                    type="submit"
                    onClick={(e) => {
                      // Check for empty fields and set errors
                      const newErrors = {};
                      Object.keys(values).forEach((key) => {
                        if (!values[key]) {
                          newErrors[key] = "This field is required";
                        }
                      });
                      if (Object.keys(newErrors).length > 0) {
                        setFormErrors(newErrors); // Notify parent component if needed
                        e.preventDefault();
                      } else {
                        handleSubmit(e);
                      }
                    }}
                    fullWidth
                    disabled={cartItems.length === 0}
                  >
                    Pregledaj narudžbu
                  </SoftButton>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </SoftBox>
    </SoftBox >
  );
}

CheckoutForm.defaultProps = {
  submittedEvent: null,
  setFormErrors: () => { },
};

CheckoutForm.propTypes = {
  submittedEvent: PropTypes.object,
  setFormErrors: PropTypes.func.isRequired,
};

export default CheckoutForm;
