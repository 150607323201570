import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "pages/layout/dashboard-layout";

import ProductImages from "pages/admin/product-details/components/product-images";
import ProductInfo from "pages/admin/product-details/components/product-info";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get, SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";

function ProductDetails() {
  const [product, setProduct] = useState(undefined);
  const { productId } = useParams();

  useEffect(() => {
    get(`${SERVER_URL_PREFIX}/api/product/${productId}`)
      .then(response => response.json())
      .then(data => setProduct(data));
  }, []);

  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <SoftBox mb={3}>
              <SoftTypography variant="h4" fontWeight="medium">
                Detalji o proizvodu
              </SoftTypography>
            </SoftBox>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                {product && <ProductImages imageUrls={product.imageLocations.map(image => `${RESOURCES_SERVER_URL_PREFIX}${image}`)} />}
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                {product && <ProductInfo
                  name={product.name}
                  price={product.unitPriceBam}
                  currency="BAM"
                  secondPrice={product.unitPriceEur}
                  secondCurrency="EUR"
                  status={product.status}
                  description={product.descriptionFields.map(field => `${field.text}`)}
                  sales={product.specialOffers.map(offer => ({ quantity: offer.quantity, price: offer.priceBAM, currency: "BAM", secondPrice: offer.priceEUR, secondCurrency: "EUR" }))}
                />}
              </Grid>
            </Grid>
          </SoftBox>
        </Card>
      </SoftBox>
    </DashboardLayout>
  );
}

export default ProductDetails;
