import { Icon, IconButton } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PropTypes from "prop-types";

const QuantityCounter = ({ value, onIncrease, onDecrease, minValue, maxValue, readOnly, sx }) => {
  const removeButtonDisabled = value <= minValue;
  const addButtonDisabled = value >= maxValue;

  return (
    <SoftBox display="flex" gap="0.4rem" sx={sx}>
      {!readOnly &&
        <IconButton
          sx={{ padding: 0, fontSize: "0.7rem" }}
          color="secondary"
          onClick={onDecrease}
          disabled={removeButtonDisabled}
        >
          <SoftBox display="flex" sx={{ backgroundColor: "#eee", borderRadius: "50%", padding: "0.1rem", opacity: removeButtonDisabled ? 0.25 : 1 }}>
            <Icon>remove</Icon>
          </SoftBox>
        </IconButton>
      }
      <SoftTypography sx={{ fontSize: "0.9rem" }}>
        {value}
      </SoftTypography>
      {!readOnly &&
        <IconButton
          sx={{ padding: 0, fontSize: "0.7rem" }}
          variant="contained"
          onClick={onIncrease}
          disabled={addButtonDisabled}
        >
          <SoftBox display="flex" sx={{ backgroundColor: "#eee", borderRadius: "50%", padding: "0.1rem", opacity: addButtonDisabled ? 0.25 : 1 }}>
            <Icon>add</Icon>
          </SoftBox>
        </IconButton>
      }
    </SoftBox>
  )
}

QuantityCounter.defaultProps = {
  value: "",
  onIncrease: () => { },
  onDecrease: () => { },
  minValue: 1,
  maxValue: 9,
  readOnly: false,
};

QuantityCounter.propTypes = {
  value: PropTypes.number,
  onIncrease: PropTypes.func,
  onDecrease: PropTypes.func,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  readOnly: PropTypes.bool,
};


export default QuantityCounter;