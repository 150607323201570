import React from 'react';
import SoftBox from "components/SoftBox";
import { RESOURCES_SERVER_URL_PREFIX } from "api";

const Signature = () => (
  <SoftBox display="flex" justifyContent="space-between" px="2rem" py="2rem">
    <SoftBox></SoftBox>
    <SoftBox>
      <SoftBox
        component="img"
        src={`${RESOURCES_SERVER_URL_PREFIX}/static/images/potpis.png`}
        alt="Natalija Todorovic potpis"
        sx={{ height: "2.4rem", px: "0.4rem" }} />
    </SoftBox>
    <SoftBox></SoftBox>
  </SoftBox>
);

export default Signature;