import { Divider, Grid, Icon, Link } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PageLayout from "pages/layout/page-layout";
import ProductItem from "pages/user/configurator/product-item";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SoftButton from "components/SoftButton";
import { get, SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";
import { useDocumentTitle } from "hooks/use-document-title";
import { ReactComponent as Priority } from 'assets/icons/priority.svg';

const ThankYou = () => {
  useDocumentTitle("Mama za pet - Thank You Page");

  const { orderId } = useParams();
  const [order, setOrder] = useState();

  useEffect(() => {
    get(`${SERVER_URL_PREFIX}/api/web/order/public/${orderId}`)
      .then(response => response.json())
      .then(data => setOrder(data));
  }, [])

  return (
    <PageLayout>
      {order &&
        <Grid container>
          <Grid item xs={12}>
            <Divider sx={{ mb: "0" }} />
            <SoftBox display="flex" sx={({ palette }) => ({
              flexDirection: 'column',
              backgroundColor: palette.success.main,
              p: { xs: "1rem" },
              alignItems: 'center',
              justifyContent: "center"
            })}>
              <Priority fill='#ffffff' width={60} height={60} />
              <SoftTypography sx={({ typography }) => ({
                fontSize: { xs: typography.size.lg, lg: typography.size.xl },
                textAlign: "center",
                color: "#fff",
              })}>
                Uspješno ste kreirali narudžbu sa brojem &nbsp; <SoftTypography sx={({ typography }) => ({
                  color: "#fff",
                  fontSize: { xs: typography.size.md, lg: typography.size.xl },
                  fontWeight: typography.fontWeightBold,
                })}>
                  {orderId}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", mb: "3rem" }}>
            <SoftBox display="flex" flexDirection="column" sx={({ palette }) => ({
              backgroundColor: palette.grey[100],
              p: "2rem",
              justifyContent: "center"
            })}>
              <SoftBox display="flex" justifyContent="center" sx={{ textAlign: "center" }}>
                <SoftTypography variant="body2" sx={{ fontStyle: "italic" }}>
                  Svi podaci o narudžbi će biti poslati na Vaš email&nbsp;
                  <SoftTypography variant="body2" component="span" sx={({ typography }) => ({
                    fontWeight: typography.fontWeightBold,
                  })}>
                    {order.customer.email}
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </Grid>
          <Grid item xs={12} >
            <SoftBox display="flex" flexDirection="column" sx={{ maxWidth: "35rem", margin: "0 auto", px: "1rem" }}>
              {order.products.map(o =>
                <ProductItem
                  key={o.id}
                  id={String(o.id)}
                  name={o.name}
                  price={order.currency === "BAM" ? String(o.unitPriceBam) : String(o.unitPriceEur)}
                  unitQuantity={order.quantity}
                  currency={order.currency}
                  realPrice={order.currency === "BAM" ? o.unitPriceBam * o.ordersQuantity : o.unitPriceEur * o.ordersQuantity}
                  calculatedPrice={order.currency === "BAM" ? o.calculatedPrice : o.calculatedPrice}
                  quantity={Math.floor(o.ordersQuantity)}
                  img={`${RESOURCES_SERVER_URL_PREFIX}${o.imageLocation}`}
                  readOnly
                  variant="minimal"
                />)}
              <Divider sx={{ mt: "1.5rem" }} />
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" mt="0.5rem">
                <SoftBox>
                  <SoftTypography variant="body2">
                    Ukupno &nbsp;
                    <SoftTypography variant="caption">
                      (uključujući dostavu i popuste)
                    </SoftTypography>
                  </SoftTypography>
                </SoftBox>
                <SoftTypography sx={({ typography }) => ({
                  fontSize: typography.size.md,
                  color: '#98999e',
                })}>
                  &nbsp;&nbsp;{order.price} {order.currency}
                </SoftTypography>
              </SoftBox>
            </SoftBox>

          </Grid>
          <Grid item xs={12} mt="5rem" mb="1rem">
            <SoftBox display="flex" justifyContent="center" sx={{ margin: "0 auto" }}>
              <SoftButton
                component={Link}
                href="/store"
                color="info"
                variant="gradient"
                sx={{ maxWidth: "25rem" }}
                fullWidth
              >
                <Icon>shopping_bag</Icon>&nbsp; Nastavi kupovinu
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      }
    </PageLayout>
  )
}

export default ThankYou;